import styled from "styled-components";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";
import { CustomForm } from "../../Shared/Form";

export const ContactForm = () => {
	const screen = useWindowMediaQuery();
	return (
		<FormStyled screen={screen}>
			<h1>We'd love to work with you</h1>
			<p>
				{" "}
				Let’s talk about your project and how we can help provide value.
				Team@explorator.ca
			</p>

			<CustomForm />
		</FormStyled>
	);
};

const FormStyled = styled.div`
	max-width: 740px;
	margin: 0 auto;
	margin-top: ${({ screen }) => (screen.xl ? "60px" : "30px")};

	h1 {
		font-weight: ${({ screen }) =>
			screen.lg ? "normal" : screen.md ? "bold" : "normal"};
		font-size: ${({ screen }) =>
			screen.lg ? "64px" : screen.md ? "50px" : "42px"};
		line-height: ${({ screen }) =>
			screen.lg ? "72px" : screen.md ? "64px" : "56px"};
		max-width: ${({ screen }) => (screen.lg ? "576px" : "100%")};
		color: var(--bs-primary);
		margin-bottom: 12px;
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		max-width: 531px;
		color: var(--bs-primary);
		margin-bottom: 51px;
	}
`;
