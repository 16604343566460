import { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import {
	Design,
	Engineering,
	Growth,
	DesignSvg,
	EnggSvg,
	GrowthSvg,
} from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const FieldsIntroSection = () => {
	const screen = useWindowMediaQuery();
	const [selectedHoverState, setSelectedHoverState] = useState(null);

	return (
		<FieldIntroStyled screen={screen}>
			<Col md={4}>
				<h4>
					<img
						onMouseEnter={() => setSelectedHoverState("Design")}
						onMouseLeave={() => setSelectedHoverState(null)}
						src={selectedHoverState === "Design" ? Design : DesignSvg}
						width={screen.xl ? "130px" : "80px"}
						height={screen.xl ? "125px" : "80px"}
						alt=""
					/>{" "}
					Design
				</h4>
				<p>
					Making apps for the web is an art form that Explorator has perfected. Users
					expect a premium <b>visual design</b> from apps they consistently use,
					making
					<b> UI/UX</b> a critical aspect of success on the web.
				</p>
			</Col>
			<Col md={4}>
				<h4>
					<img
						onMouseEnter={() => setSelectedHoverState("Engg")}
						onMouseLeave={() => setSelectedHoverState(null)}
						src={selectedHoverState === "Engg" ? Engineering : EnggSvg}
						width={screen.xl ? "130px" : "80px"}
						height={screen.xl ? "125px" : "80px"}
						alt=""
					/>{" "}
					Engineering
				</h4>
				<p>
					We engineer <b>clean, shippable</b> products by utilizing modern technology
					frameworks and code stacks. We code <b>custom frontend</b> and{" "}
					<b>backend solutions</b>, and we specialize in integrating with
					best-of-breed platforms.
				</p>
			</Col>
			<Col md={4}>
				<h4>
					<img
						onMouseEnter={() => setSelectedHoverState("Growth")}
						onMouseLeave={() => setSelectedHoverState(null)}
						src={selectedHoverState === "Growth" ? Growth : GrowthSvg}
						width={screen.xl ? "130px" : "80px"}
						height={screen.xl ? "125px" : "80px"}
						alt=""
					/>{" "}
					Growth
				</h4>
				<p>
					Building a premium app is just half the battle. In order to be successful
					on the web, you also need a team that knows how to acquire and retain
					users. We have deep expertise in <b>growing web apps</b> and{" "}
					<b>SaaS platforms</b> with a keen eye on metrics that are critical.
				</p>
			</Col>
		</FieldIntroStyled>
	);
};

const FieldIntroStyled = styled(Row)`
	margin-bottom: 75px;
	margin-top: ${({ screen }) => (screen.xl ? "200px" : "80px")};

	> div {
		margin-bottom: ${({ screen }) => (screen.md ? "0" : "40px")};
	}

	img {
		margin-left: -20px;
	}

	h4 {
		font-weight: bold;
		font-size: 24px;
		line-height: 42px;
		color: var(--bs-primary);
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: var(--bs-primary);
	}
`;
