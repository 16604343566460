import { CareerPageComponent } from "../Components/Root/CareerPage";
import CustomLayout from "../Layout/Layout";

const CareerPage = () => {
	return (
		<CustomLayout>
			<CareerPageComponent />
		</CustomLayout>
	);
};

export default CareerPage;
