import { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import {
	MasteryDemandsFocus,
	PaidAdvertisingIcon,
	MasteryDemandJpeg,
	PaidAdvertising,
} from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const WhatWeOfferSection = () => {
	const screen = useWindowMediaQuery();
	const [hoverState, setHoverState] = useState(null);
	return (
		<WhatWeOfferSectionStyled screen={screen}>
			<h6>Services</h6>
			<h3>What We Offer?</h3>

			<Row className="content-style">
				<Col md={6}>
					<p>
						Here at Explorator Labs, we work with the best resources and talent in the
						industry to produce millions of dollars in return on ad spend. We do away
						with the inefficiencies & formalities that plague most agencies.
					</p>
				</Col>
				<Col md={6}>
					<p>
						Work with a results driven team whose sole purpose is to help you achieve
						your marketing and sales objectives.
					</p>
				</Col>
			</Row>

			<PurposeSection screen={screen}>
				<div>
					<Row>
						<Col
							lg={4}
							md={4}
							xs={5}
							onMouseEnter={() => setHoverState("paid")}
							onMouseLeave={() => setHoverState(null)}>
							{" "}
							<img
								src={hoverState === "paid" ? PaidAdvertisingIcon : PaidAdvertising}
								width="112px"
								height="127px"
								alt=""
							/>
						</Col>
						<Col lg={8} md={8} xs={7} className="d-flex align-items-center">
							<h4> Paid Advertising</h4>
						</Col>
					</Row>
				</div>
				<div className="text-md-right">
					<Row>
						<Col
							lg={4}
							md={4}
							xs={5}
							onMouseEnter={() => setHoverState("mastery")}
							onMouseLeave={() => setHoverState(null)}>
							{" "}
							<img
								src={hoverState === "mastery" ? MasteryDemandsFocus : MasteryDemandJpeg}
								width="112px"
								height="127px"
								alt=""
							/>
						</Col>
						<Col lg={8} md={8} xs={7} className="d-flex align-items-center">
							<h4> Mastery Demands Focus</h4>
						</Col>
					</Row>
				</div>
			</PurposeSection>
		</WhatWeOfferSectionStyled>
	);
};

const WhatWeOfferSectionStyled = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 0 auto;
	margin-bottom: ${({ screen }) => (screen.md ? "115px" : "70px")};
	max-width: var(--max-width-content);

	h6 {
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		color: var(--bs-primary);
	}

	h3 {
		font-weight: bold;
		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "42px" : "24px"};
		line-height: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "56px" : "42px"};
		margin-bottom: ${({ screen }) => (screen.md ? "35px" : "10px")};
		color: var(--bs-primary);
	}

	.content-style {
		margin-bottom: 35px;
		p {
			font-weight: normal;
			font-size: 16px;
			line-height: 28px;
			color: var(--bs-primary);
		}
	}
`;

const PurposeSection = styled.div`
	display: flex;
	flex-direction: ${({ screen }) => (screen.md ? "row" : "column")};
	justify-content: space-between;
	max-width: ${({ screen }) => (screen.xl ? "80%" : "100%")};
	margin: 0 auto;

	> div {
		width: ${({ screen }) => (screen.md ? "50%" : "100%")};
	}

	img {
		max-width: inherit;
	}

	h4 {
		font-weight: 600;
	}
`;
