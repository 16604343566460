import { ContactPageComponent } from "../Components/Root/ContactPage";
import CustomLayout from "../Layout/Layout";

const ContactPage = () => {
	return (
		<CustomLayout>
			<ContactPageComponent />
		</CustomLayout>
	);
};

export default ContactPage;
