import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { CareerPageCurves, Star_Team } from "../../Utils/ImageImportFile";
import { TeamCards } from "./TeamCards";
import { HavingFunSection } from "./HavingFunSection";
import { YourTurnContainer } from "./YourTurn";

import useWindowMediaQuery from "../../../Hooks/MediaQuery";

const teamContent = [
	{
		title: "Team Players",
		text:
			"We all need someone to back us up sometimes. At Explorator no one works on an island. We foster a culture of mutual support, and everyone lends a hand to help a friend.",
	},
	{
		title: "Good People",
		text:
			"We seek team members who are opinionated but considerate, passionate but respectful, and hard-working but balanced. We are fortunate to have a track record of surrounding ourselves with down-to-earth friends.",
	},
	{
		title: "Diverse Talent",
		text:
			"We welcome folks from a diverse set of skills and personal backgrounds, adding additional flavor and perspectives to all our client projects.",
	},
];

const teamContent2 = [
	{
		title: "Working Smart",
		text: `At Explorator Labs; We believe in an independant approach to projects yet no one works on an island. 
We foster a culture of mutual support, and everyone lends a hand to help when needed in order to stimulate growth within the company`,
	},
	{
		title: "Rewarding Projects",
		text: `We seek team members who are opinionated but considerate, passionate but respectful, and hard-working but balanced. We are fortunate to have a track record of surrounding ourselves with down-to-earth friends.`,
	},
	{
		title: "Growth Focused Ecosystem",
		text:
			"We believe in growth and learning at Explorator, and we foster an environment that supports career and professional advancement. We want our folks to be challenge in and out of the workplace.",
	},
];

export const CareerPageComponent = () => {
	const screen = useWindowMediaQuery();
	return (
		<CareerCompStyled screen={screen}>
			<CurvesStyled>
				<div>
					<img src={CareerPageCurves} alt="" />
				</div>
			</CurvesStyled>

			<MainContentText screen={screen}>
				<Row>
					<Col md={7}>
						<Box screen={screen}>
							<Col className="flex-grow-1">
								<h1>An All-Star team, minus the egos</h1>
								<p>
									We’re a team of talented individuals who are team players and friends.
									We look for folks who want to join a group that values personal growth
									and autonomy while understanding the greater purpose.{" "}
								</p>
							</Col>
							<Col className="flex-grow-0">
								<h4>
									But first, a little bit about us.
									<br /> What you should know about Explorator.
								</h4>
							</Col>
						</Box>
					</Col>
					<Col md={5} className="text-center image-col">
						<img src={Star_Team} alt="" />
					</Col>
				</Row>
			</MainContentText>

			<TeamCards teamContent={teamContent} />
			<HavingFunSection />
			<TeamCards teamContent={teamContent2} />
			<YourTurnContainer />
		</CareerCompStyled>
	);
};

const CareerCompStyled = styled.div`
	position: relative;
	overflow-x: hidden;
`;

const CurvesStyled = styled.div`
	position: relative;

	img {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		/* width: 90vw; */
	}
`;

const MainContentText = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	max-width: var(--max-width-content);
	padding-top: ${({ screen }) =>
		screen.xl ? "130px" : screen.md ? "70px" : "50px"};
	margin: 0 auto;
	margin-bottom: ${({ screen }) =>
		screen.xl ? "170px" : screen.md ? "140px" : "40px"};

	.image-col > img {
		max-width: ${({ screen }) =>
			screen.xl ? "350px" : "-webkit-fill-available"};
		max-height: 433px;
	}
`;

const Box = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;

	h1 {
		font-weight: ${({ screen }) =>
			screen.xl ? "normal" : screen.md ? "bold" : "normal"};
		font-size: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "50px" : "42px"};
		line-height: ${({ screen }) =>
			screen.xl ? "72px" : screen.md ? "64px" : "56px"};
		margin-bottom: 11px;
		color: var(--bs-primary);
	}

	p {
		max-width: 550px;
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: var(--bs-primary);
	}

	h4 {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.xl ? "32px" : "24px")};
		line-height: ${({ screen }) => (screen.xl ? "48px" : "32px")};
		color: #000;
		max-width: 640px;
		margin-top: ${({ screen }) => (screen.md ? "0" : "56px")};
		margin-bottom: ${({ screen }) => (screen.md ? "0" : "40px")};
	}
`;
