import { useState } from "react";
import styled from "styled-components";
import { Navbar, Nav, Offcanvas, Row, Col } from "react-bootstrap";
import { HeaderLogo, HeaderLogoMobile } from "../../Utils/ImageImportFile";
import { useHistory, useLocation } from "react-router";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export function HeaderComp() {
	const location = useLocation();
	const history = useHistory();
	const [showMenu, setShowMenu] = useState(false);

	const hideMenu = () => setShowMenu(false);

	const screen = useWindowMediaQuery();

	return (
		<HeaderStyled screen={screen}>
			<Navbar>
				<Navbar.Brand href="/">
					<img src={HeaderLogo} alt="" />
				</Navbar.Brand>
				<Nav className="ms-auto">
					{screen.xl && (
						<>
							<Nav.Link active={location.pathname === "/home"} href="/home">
								Home
							</Nav.Link>
							<Nav.Link active={location.pathname === "/about"} href="/about">
								About
							</Nav.Link>
							<Nav.Link active={location.pathname === "/approach"} href="/approach">
								Approach
							</Nav.Link>
							<Nav.Link active={location.pathname === "/marketing"} href="/marketing">
								Marketing
							</Nav.Link>
							<Nav.Link active={location.pathname === "/career"} href="/career">
								Careers
							</Nav.Link>
						</>
					)}
					
					{screen.lg && !screen.xl && (
						<Nav.Link active={location.pathname === "/approach"} href="/approach">
							Approach
						</Nav.Link>
					)}

					{screen.md && !screen.xl && (
						<Nav.Link active={location.pathname === "/contact"} href="/contact">
							Contact Us
						</Nav.Link>
					)}

					{!screen.xl && (
						<Nav.Link active={false} href="#" onClick={() => setShowMenu(true)}>
							<span className="material-icons">menu</span>
						</Nav.Link>
					)}
				</Nav>
			</Navbar>

			<OffcanvasStyled
				screen={screen}
				backdropClassName="menu-backdrop"
				show={showMenu}
				onHide={hideMenu}
				placement="end">
				<Offcanvas.Header className="justify-content-end">
					<span onClick={hideMenu} className="material-icons cursor-pointer">
						close
					</span>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Row className="menus-row justify-content-between">
						{screen.md && (
							<Col xs={5} className="our-work-contact">
								<h1 onClick={() => history.push("/approach")}>Our Work</h1>
								<h1 onClick={() => history.push("/contact")}>Contact Us</h1>
							</Col>
						)}
						<Col xs={screen.md ? 5 : 12} className="menu-list">
							<h6>MENU</h6>

							<h5>
								<a href="/">Home</a>
							</h5>
							<h5>
								<a href="/about">About</a>
							</h5>
							{!screen.lg && (
								<h5>
									<a href="/approach">Approach</a>
								</h5>
							)}
							<h5>
								<a href="/marketing">Marketing</a>
							</h5>
							<h5>
								<a href="/career">Careers</a>
							</h5>
							{!screen.md && (
								<h5>
									<a href="/contact">Contact Us</a>{" "}
								</h5>
							)}
						</Col>
					</Row>
				</Offcanvas.Body>
			</OffcanvasStyled>
		</HeaderStyled>
	);
}

const HeaderStyled = styled.div`
	.navbar-brand > img {
		width: ${({ screen }) => (screen.md ? "240px" : "200px")};
		height: ${({ screen }) => (screen.md ? "40px" : "35px")};
	}
	.navbar {
		max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "88%")};
		margin: 0 auto;
		padding: 40px 0;

		.nav-link {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 30px;
			color: var(--bs-primary);
			padding: 0;
			margin: 0 22px;
		}

		.navbar-nav .nav-link.active,
		.navbar-nav .show > .nav-link {
			border-bottom: 2px solid var(--bs-primary);
		}
	}
`;

const OffcanvasStyled = styled(Offcanvas)`
	width: ${({ screen }) => (screen.md ? "75%" : "100%")};
	background: rgba(254, 254, 255, 0.8);
	backdrop-filter: blur(15px);
	border: none;

	.offcanvas-header {
		padding: ${({ screen }) => (screen.md ? "30px 165px" : "30px 45px")};
	}

	.menus-row {
		.our-work-contact {
			margin-top: 30px;
			padding-left: ${({ screen }) => (screen.xl ? "100px" : "40px")};
		}

		.menu-list {
			padding-right: 70px;
			padding-left: ${({ screen }) => (screen.md ? "0" : "30px")};
		}
	}

	h1 {
		font-weight: 500;
		font-size: ${({ screen }) => (screen.xl ? "50px" : "30px")};
		line-height: ${({ screen }) => (screen.xl ? "80px" : "60px")};
		margin-bottom: 10px;
		color: var(--bs-gray-100);
		cursor: pointer;
	}

	h5 {
		margin-bottom: 35px;
		a {
			font-size: 28px;
			line-height: 40px;
			font-weight: normal;
			cursor: pointer;
			text-decoration: none;
			color: var(--bs-primary);
		}
	}

	h6 {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 30px;
		color: ${({ screen }) => (screen.md ? "#000" : "#323232")};
		text-transform: uppercase;
	}
`;
