import { CaseStudyComponent } from "../Components/Root/CaseStudyPage";
import CustomLayout from "../Layout/Layout";

const CaseStudyPage = () => {
	return (
		<CustomLayout>
			<CaseStudyComponent />
		</CustomLayout>
	);
};

export default CaseStudyPage;
