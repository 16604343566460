import { ApproachPageComponent } from "../Components/Root/ApproachPage";
import CustomLayout from "../Layout/Layout";

const ApproachPage = () => {
	return (
		<CustomLayout>
			<ApproachPageComponent />
		</CustomLayout>
	);
};

export default ApproachPage;
