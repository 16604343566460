import styled from "styled-components";
import { Button } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const LetsTalkComponent = () => {
	const screen = useWindowMediaQuery();
	return (
		<TalkCompStyled screen={screen}>
			<div>
				<h1>Let’s Talk</h1>
				<h4>Let’s talk about your project and how we can help provide value.</h4>
				<p>
					We work with clients of all sizes, from enterprise brands and funded
					startups to companies at the earliest stage.
				</p>

				<ButtonStyled variant="dark" href="/contact" rel="noopener noreferrer" >
					Let's Talk <span className="material-icons">keyboard_arrow_right</span>
				</ButtonStyled>
			</div>
		</TalkCompStyled>
	);
};

const TalkCompStyled = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 0 auto;
	margin-top: 195px;
	margin-bottom: 155px;
	max-width: var(--max-width-content);

	div {
		max-width: 850px;
	}

	h1 {
		font-weight: normal;
		font-size: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "50px" : "32px"};
		line-height: ${({ screen }) =>
			screen.xl ? "72px" : screen.md ? "64px" : "42px"};
		margin-top: 6px;
		color: var(--bs-primary);
	}

	h4 {
		margin-top: 10px;
		font-weight: normal;
		font-size: ${({ screen }) => (screen.md ? "24px" : "18px")};
		line-height: ${({ screen }) => (screen.md ? "36px" : "24px")};
		margin-bottom: 10px;
		color: var(--bs-primary);
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		max-width: 546px;
		color: var(--bs-primary);
	}
`;

const ButtonStyled = styled(Button)`
	&.btn {
		margin-left: auto;
		margin-top: 30px;
		border-radius: 50px;
		width: 140px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--bs-gray-100);
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: var(--bs-secondary);
		border-color: var(--bs-gray-100);

		.material-icons {
			margin-left: 10px;
		}
	}
`;
