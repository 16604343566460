import styled from "styled-components";
import { Row, Col, Button } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

const LeftImageCaseStudy = ({
	mainImg,
	companyIcon,
	title,
	text,
	link,
	...props
}) => {
	const screen = useWindowMediaQuery();
	return (
		<LeftImageCaseStudyStyled {...props} screen={screen}>
			<Col className="image-col text-start" lg={7} md={6}>
				<img src={mainImg} alt="" className="img-fluid" />
			</Col>
			<Col className="content-box" lg={5} md={6}>
				<ContentBox screen={screen}>
					<h5>{title}</h5>
					<img src={companyIcon} className="company-icon-size img-fluid" alt="" />

					<p>{text}</p>

					<div className="text-right">
						<ButtonStyled
							href={link}
							rel="noopener noreferrer"
							variant="dark"
							screen={screen}>
							Learn More <span className="material-icons">keyboard_arrow_right</span>
						</ButtonStyled>
					</div>
				</ContentBox>
			</Col>
		</LeftImageCaseStudyStyled>
	);
};

const RightImageCaseStudy = ({
	mainImg,
	companyIcon,
	title,
	text,
	link,
	...props
}) => {
	const screen = useWindowMediaQuery();
	return (
		<RightImageCaseStudyStyled {...props} screen={screen}>
			<Col className="content-box order-md-0 order-1" lg={5} md={6}>
				<ContentBox screen={screen}>
					<h5>{title}</h5>
					<img src={companyIcon} className="company-icon-size img-fluid" alt="" />

					<p>{text}</p>

					<div className="text-right">
						<ButtonStyled
							variant="dark"
							href={link}
							rel="noopener noreferrer"
							screen={screen}>
							Learn More <span className="material-icons">keyboard_arrow_right</span>
						</ButtonStyled>
					</div>
				</ContentBox>
			</Col>

			<Col className="image-col text-end order-md-1 order-0" lg={7} md={6}>
				<img src={mainImg} alt="" className="img-fluid" />
			</Col>
		</RightImageCaseStudyStyled>
	);
};

const LeftImageCaseStudyStyled = styled(Row)`
	margin: 0;
	align-items: center;
	padding: ${({ screen }) => (screen.md ? "unset" : "0")};

	.company-icon-size {
		max-width: 70%;
		max-height: 80px;
	}

	.image-col {
		img {
			/* max-width: ${({ screen }) => (screen.md ? "500px" : "100%")};
			max-height: ${({ screen }) => (screen.md ? "500px" : "100%")}; */
		}
	}
`;

const RightImageCaseStudyStyled = styled(Row)`
	margin: 0;
	margin-top: ${({ screen }) => (screen.md ? "150px" : "30px")};
	align-items: center;

	.company-icon-size {
		max-width: 100%;
		max-height: 80px;
	}

	.image-col {
		img {
		}
	}
`;

const ContentBox = styled.div`
	margin-left: ${({ screen }) => (screen.xl ? "20px" : "0")};

	h5 {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.xl ? "24px" : "20px")};
		line-height: 32px;
		color: var(--bs-primary);
	}

	img {
		/* width: ${({ screen }) => (screen.xl ? "150px" : "140px")}; */
		/* height: ${({ screen }) => (screen.xl ? "50px" : "45px")}; */
	}

	p {
		margin-top: 26px;
		margin-bottom: ${({ screen }) => (screen.xl ? "76px" : "30px")};
		font-weight: normal;
		font-size: ${({ screen }) =>
			screen.xl ? "16px" : screen.md ? "15px" : "18px"};
		line-height: 28px;
		max-width: 440px;
	}
`;

const ButtonStyled = styled(Button)`
	&.btn {
		position: relative;
		margin-left: auto;
		margin-top: 30px;
		border-radius: 50px;
		width: 140px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding-left: 20px;
		background: var(--bs-gray-100);
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: var(--bs-secondary);
	}
`;

export { LeftImageCaseStudy, RightImageCaseStudy };
