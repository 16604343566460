import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const AboutUsComponent = () => {
	const screen = useWindowMediaQuery();

	return (
		<AboutCompStyled screen={screen}>
			<h6>About Us</h6>
			<h3>Who We Are</h3>

			<Box screen={screen}>
				<Col xl={5} md={6}>
					<p>
						Explorator is a web application development firm based in Ottawa, Canada.
						Since 2019, we’ve built web apps for both large enterprise brands and
						startup ventures across Canada and more.
					</p>
					<p>
						Founded by digital leaders and technologists with extensive background in
						web development, Explorator is built by folks that have been on the other
						side of the table and have an extensive entrepreneurial experience.
					</p>
				</Col>
				<Col xl={7} md={6}>
					<p className="my-0 mx-auto">
						We treat every design and development project as if it was our own in
						order to simplify the process of turning your idea into reality.
					</p>
				</Col>
			</Box>
		</AboutCompStyled>
	);
};

const AboutCompStyled = styled.div`
	position: relative;

	h6 {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.md ? "24px" : "18px")};
		line-height: ${({ screen }) => (screen.md ? "32px" : "30px")};
		color: var(--bs-primary);
	}

	h3 {
		font-weight: bold;

		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "42px" : "24px"};
		line-height: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "56px" : "42px"};
		color: var(--bs-primary);
		margin-bottom: ${({ screen }) => (screen.md ? "35px" : "22px")};
	}
`;

const Box = styled(Row)`
	flex-direction: ${({ screen }) => (screen.md ? "row" : "column")};
	div > p {
		max-width: ${({ screen }) => (screen.xl ? "475px" : "100%")};
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		margin-bottom: ${({ screen }) => (screen.xl ? "25px" : "50px")};
		color: var(--bs-primary);
	}
`;
