import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { GetYouBusinessOnline } from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const OurValueProp = () => {
	const screen = useWindowMediaQuery();
	return (
		<OurValuePropStyled screen={screen}>
			<h4>Get your business online today!</h4>
			
			<Row>
				<Col className="our-value-image" md={6}>
					<img src={GetYouBusinessOnline} alt=""/>
				</Col>
				<Col className="our-value-content" md={6}>
					<p>
					Your digital presence may be limited due to your limited budget as a small business. 
					With prices starting at under 300 dollars, we strive to get you a high-quality digital 
					presence at an affordable price.
					
					</p>
					<ul>
						<li><span className="material-icons">done_all </span>Create website starting at $299</li>
						<li><span className="material-icons">done_all</span>Choose from our affordable website packages or create your own</li>
						<li><span className="material-icons">done_all</span>Free Consultation</li>
						<li><span className="material-icons">done_all</span>Unlimited Revisions till Satisfaction </li>
						<li><span className="material-icons">done_all</span>One Time Payment, No Monthly/Yearly Fee</li>
					</ul>
				</Col>
			</Row>
		</OurValuePropStyled>
	);
};

const OurValuePropStyled = styled(Row)`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
	margin: ${({ screen }) => (screen.md ? "170px auto 120px auto" : "100px auto")};

	.material-icons{
		display: inline-block;
	}

	h4 {
		font-size: 35px;
		font-weight: 500;
		color: var(--bs-primary);
		margin-bottom: ${({ screen }) => (screen.md ? "50px" : "25px")};
	}

	h3 {
		font-size: ${({ screen }) =>
			screen.xl ? "56px" : screen.md ? "42px" : "32px"};
		line-height: ${({ screen }) =>
			screen.xl ? "66px" : screen.md ? "56px" : "42px"};
		font-weight: bold;
		color: var(--bs-primary);
		margin-bottom: ${({ screen }) => (screen.md ? "50px" : "25px")};
	}

	.our-value-image {
		img {
			width: 100%;
		}
	}

	.our-value-content {
		padding: ${({ screen }) => (screen.xl ? "0 32px" : "0 16px")};

		p {
			font-weight: normal;
			font-size: 18px;
			line-height: 30px;
			margin-top: ${({ screen }) => (screen.md ? "0" : "30px")};
			margin-bottom: 30px;
		}
		ul{
			padding-left: 0px;
			list-style: none;
		}
		li{
			display: flex;
			padding-bottom: 20px;
			span{
				padding-right: 7px;
			}
		}
	}
`;
