import {
  AWS,
  Css3,
  FloatCompImg,
  FloatIPadImg,
  FloatLogo,
  FloatPhoneImg,
  Html5,
  JavaScript,
  Laptop_Mobile_Mockup_2,
  OttawaCompImage,
  OttawaMainImage,
  OttawaMobileImage,
  PivotCompImg,
  PivotIPadImg,
  PivotLogo,
  PivotMainImg,
  PivotPhoneImg,
  Rails,
  Ruby,
  SQL,
  OttawaLogo,
  OttawaIPadImage,
  GlobalPassLogo2,
  GlobalPassCompIMg,
  GlobalPassMobileIMg,
  GlobalPassIPadIMg,
  GlobalPassMainIMg,
  OshLogo,
  Laptop_Mobile_Mockup_7,
  OshCompImg,
  OshMobileImg,
  OshTabletImg,
  WaveRoomLogo,
  Laptop_Mobile_Mockup_8,
  React,
  Firebase,
  WavroomTabletImg,
  WavroomMobileImg,
  Wavroom4kImg,
} from "./ImageImportFile";

const pivotCaseStudy = {
  backgroundGradientColor:
    "linear-gradient(278.44deg, #fdac34 -2.61%, #f8f9fa 92.85%)",
  logo: PivotLogo,
  headerParaText: "Employee scheduling and management system",
  heroImage: PivotMainImg,
  site_link: "https://pivot.jobs/",

  client: ["Pivot"],
  timeline: ["8 Months"],
  serviceType: [
    "Discovery",
    "UX Design",
    "Creative Design",
    "Technical Implementation",
  ],
  platform: ["Website", "IOS", "Android"],

  projectScobeText: `To build a shift management software with integration of reservations,
				optimizes the management of schedules, tips and payroll in addition to
				facilitating communication within your company.`,
  problemOverview: `The team at Pivot wanted to create a new platform with the objective of optimizing the management of schedules and drastically reduce the management overhead, hence, decrease cost and increase efficiency. `,
  problemOverviewImg: PivotCompImg,

  techOutcomeText: "Created an web app and mobile app which:",
  techOutcomeList: [
    "Aggregates employee information and constraints",
    "Meets the clients needs and overcame their obstacle",
    "Provide managers optimize scheduling for employee needs",
  ],
  techOutcomeImage: PivotPhoneImg,

  businessOutcomeText: [
    "50% Increased employee satisfaction",
    "",
    "25% Less employee turnover",
  ],
  businessOutcomeImage: PivotIPadImg,
};

const floatCaseStudy = {
  backgroundGradientColor:
    "linear-gradient(278.44deg, #A4C3FF -2.61%, #F8F9FA 92.85%);",
  logo: FloatLogo,
  headerParaText:
    "Toronto-based company specialized in storing data and displaying services in the boating industry.",
  heroImage: Laptop_Mobile_Mockup_2,
  site_link: "https://bookwithfloat.com",

  client: ["Float"],
  timeline: ["4 Months"],
  serviceType: [
    "Competitive anlysis",
    "Tentative User Surveys",
    "Prototype Creation",
    "Curation of UX Design",
    "Technical Implementation",
    "Product Launch",
  ],
  platform: ["Website"],

  projectScobeText: `To create a mobile-friendly application that has an internal reservation system that allows customers to reserve and pay on the website.`,
  problemOverview: `To build a platform from the ground up which can handle a high volume of payment and bookings request. Also, a management panel with user various roles and operations. `,
  problemOverviewImg: FloatCompImg,

  techStack: [JavaScript, SQL, Ruby, Rails, Html5, Css3, AWS],

  techOutcomeText: "Completed Web App & Mobile App which:",
  techOutcomeList: [
    "Allows vendors to manage booking and listing",
    "Allows users to rent boats months in advance",
    "Has a flexible reservation system",
  ],
  techOutcomeImage: FloatPhoneImg,

  businessOutcomeText: [
    "Increased customer satisfaction",
    "Increased customer retention",
    "Increased website traffic",
  ],
  businessOutcomeImage: FloatIPadImg,
};

const ottawaCaseStudy = {
  backgroundGradientColor:
    " linear-gradient(278.44deg, #8FD1C8 -2.61%, #F8F9FA 92.85%)",
  logo: OttawaLogo,
  headerParaText:
    "Non-profit government-proven organization that improves the health and access for people who are chronically homeless.",
  heroImage: OttawaMainImage,
  site_logo: "#",
  site_link: "https://www.ottawainnercityhealth.ca/",

  client: ["Ottawa Inner", "City Health"],
  timeline: ["1 year"],
  serviceType: [
    "Discovery",
    "UX Design",
    "Creative Design",
    "Technical Implementation",
  ],
  platform: ["Web App", "Mobile App"],

  projectScobeText: `To create a website and a mobile application that digitize paperwork and gather information from various resources to save labor and time.`,
  problemOverview: `To build a web platform that digitizes health workers' workflow, allowing them to focus on what matters the most, Lives.`,
  problemOverviewImg: OttawaCompImage,

  techStack: [JavaScript, SQL, Ruby, Rails],

  techOutcomeText: "Development of Mobile-Friendly App that: ",
  techOutcomeList: [
    "Store client’s data anonymously and securely",
    "Enable 3-click access to anything client-related information",
    "Enable report generation for federal reporting",
  ],
  techOutcomeImage: OttawaMobileImage,

  businessOutcomeText: [
    "Increased working efficiency",
    "Decreased overlap of documents",
    "Improvement information sharing processes between within the site inventory and partner organizations",
  ],
  businessOutcomeImage: OttawaIPadImage,
};

const globalPassCaseStudy = {
  backgroundGradientColor:
    "linear-gradient(278.44deg, #BED4FE -2.61%, #F8F9FA 92.85%);",
  logo: GlobalPassLogo2,
  headerParaText:
    "Education Technology Company that helps international students apply to university and study visa",
  heroImage: GlobalPassMainIMg,
  site_link: " https://theglobalpass.com/",

  client: ["GlobalPass"],
  timeline: ["1 year"],
  serviceType: [
    "Discovery",
    "UX Design",
    "Creative Design",
    "Technical Implementation",
  ],
  platform: ["Web App", "Mobile App"],

  projectScobeText: `To create a CRM website and a mobile application that digitize paperwork and gather information from various resources to in order to streamline the onboarding process for thousands of clients.`,
  problemOverview: `Available upon request`,
  problemOverviewImg: GlobalPassCompIMg,

  techStack: [JavaScript, SQL, Ruby, Rails],

  techOutcomeText: "Available upon request",
  techOutcomeList: [],
  techOutcomeImage: GlobalPassMobileIMg,

  businessOutcomeText: [
    "Acquisition of nearly 10,000 organic new users in 6 months",
    "Development of propriatary applications (Web and mobile - IOS + android)",
    "Improvement of UI/UX for better user navigation",
    "Ready for first round of investments",
  ],
  businessOutcomeImage: GlobalPassIPadIMg,
};

const oshCaseStudy = {
  backgroundGradientColor:
    "linear-gradient(278.44deg, #766ba8 -2.61%, #F8F9FA 92.85%);",
  logo: OshLogo,
  headerParaText:
    "Offshorehires is a platform that connects businesses with highly skilled and affordable freelancers to support business growth.",
  heroImage: Laptop_Mobile_Mockup_7,
  site_link: " https://offshore-hires.dokku.explorator.ca/",

  client: ["Offshore Hire"],
  timeline: ["2 year"],
  serviceType: [
    "Discovery",
    "UX Design",
    "Creative Design",
    "Technical Implementation",
  ],
  platform: ["Web App"],

  projectScobeText: `To create a leading online marketplace that connects businesses with a diverse pool of talented freelancers across a wide range of industries. From writing and graphic design to web development and beyond, the platform enables companies to find the right expertise they need to bring their projects to life.`,
  problemOverview: `Offshore-hires seeks to develop an online marketplace connecting businesses with skilled freelancers across industries. The platform will simplify the process of finding the right expertise for various services.`,
  problemOverviewImg: OshCompImg,

  techStack: [JavaScript, SQL, Ruby, Rails],

  techOutcomeText: "Create a web app marketplace which has",
  techOutcomeList: [
    "AI matching algorithm: Connects businesses with suitable freelancers.",
    "Collaboration tools: Facilitate real-time communication.",
    "Secure payment system: Ensures fair payment.",
  ],
  techOutcomeImage: OshMobileImg,

  businessOutcomeText: [
    "Increased project efficiency and productivity.",
    "Cost-effective solution for businesses.",
    "Access to a diverse range of skilled freelancers worldwide.",
  ],
  businessOutcomeImage: OshTabletImg,
};

const waveRoomCaseStudy = {
  backgroundGradientColor:
    "linear-gradient(278.44deg, #000000 -2.61%, #F8F9FA 92.85%);",
  logo: WaveRoomLogo,
  headerParaText:
    "The Wavroom produces soundwave art for music enthusiasts, using custom songs and albums. They empower customers to make waves in their space.",
  heroImage: Laptop_Mobile_Mockup_8,
  site_link: " https://wavroom-staging.web.app/",

  client: ["Wavroom"],
  timeline: ["1 year"],
  serviceType: [
    "Shopify Integration",
    "Creative Solution",
    "Spotify Integration",
    "Technical Implementation",
  ],
  platform: ["Web App"],

  projectScobeText: `To develop an innovative web solution that allows music enthusiasts to turn their favorite songs and albums into custom soundwave art. The platform empowers customers to create unique, personalized artwork that adds a touch of musical elegance to their space.`,
  problemOverview: `To build a scalable, visually stunning web solution for Wavroom to generate soundwave art from custom songs. Our team used cutting-edge technology for easy printing and shipping.`,
  problemOverviewImg: Wavroom4kImg,

  techStack: [JavaScript, React, Firebase],

  techOutcomeText: "Create a web app which",
  techOutcomeList: [
    "Generates custom soundwave art through a responsive web interface.",
    "Delivers high-quality soundwave visualizations with advanced rendering technology.",
    "Employs cloud-based technology for on-demand soundwave art generation.",
  ],
  techOutcomeImage: WavroomMobileImg,

  businessOutcomeText: [
    "Unique soundwave art for music enthusiasts worldwide.",
    "Innovative product category in art industry.",
    "Providing a profitable and sustainable revenue stream for Wavroom.",
  ],
  businessOutcomeImage: WavroomTabletImg,
};

export {
  pivotCaseStudy,
  floatCaseStudy,
  ottawaCaseStudy,
  globalPassCaseStudy,
  oshCaseStudy,
  waveRoomCaseStudy,
};
