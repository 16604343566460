import { AboutPageComponent } from "../Components/Root/AboutPage";
import CustomLayout from "../Layout/Layout";

const AboutPage = () => {
	return (
		<CustomLayout>
			<AboutPageComponent />
		</CustomLayout>
	);
};

export default AboutPage;
