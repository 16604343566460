import { MarketingPageComponent } from "../Components/Root/MarketingPage";
import CustomLayout from "../Layout/Layout";

const MarketingPage = () => {
	return (
		<CustomLayout>
			<MarketingPageComponent />
		</CustomLayout>
	);
};

export default MarketingPage;
