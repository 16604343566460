import styled from "styled-components";
import { Button } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const SpeakToTeamSection = () => {
	const screen = useWindowMediaQuery();
	return (
		<SpeakToTeamStyled screen={screen}>
			<h6>Speak to Our Team</h6>
			<h3>Schedule Your Call With The Team</h3>
			<div className="content-wrapper">
				<p>
					By the end of this Audit call, you will have a clear understanding of the
					next steps you can take for your business to start generating consistent
					and reliable results online with Funnels & Paid Advertising.
				</p>
				<br />
				<p>
					Find a time on our calendar to schedule your call today and we look forward
					to speaking to you soon!
				</p>

				<ButtonStyled variant="dark" href="/contact" rel="noopener noreferrer" >
					Let's Talk <span className="material-icons">keyboard_arrow_right</span>
				</ButtonStyled>
			</div>
		</SpeakToTeamStyled>
	);
};

const SpeakToTeamStyled = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 0 auto;
	margin-bottom: 160px;
	max-width: var(--max-width-content);

	h3 {
		font-weight: ${({ screen }) => (screen.xl ? "bold" : "500")};
		font-size: ${({ screen }) => (screen.xl ? "50px" : "42px")};
		line-height: ${({ screen }) => (screen.xl ? "64px" : "56px")};
		color: var(--bs-primary);
		margin-bottom: 35px;
	}

	h6 {
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		color: var(--bs-primary);
	}

	.content-wrapper {
		max-width: 835px;
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: var(--bs-primary);
	}
`;

const ButtonStyled = styled(Button)`
	&.btn {
		margin-left: auto;
		margin-top: 60px;
		border-radius: 50px;
		width: 140px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding-left: 20px;
		background: var(--bs-gray-100);
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: var(--bs-secondary);
		border-color: var(--bs-gray-100);
	}
`;
