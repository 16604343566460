import styled from "styled-components";
import { Button } from "react-bootstrap";
import { MarketingPageCurves } from "../../Utils/ImageImportFile";
import { WhatWeOfferSection } from "./WhatWeOffer";
import { AuditCallPerfectComp } from "./AuditCallPerfect";
import { SpeakToTeamSection } from "./SpeakToTeam";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const MarketingPageComponent = () => {
	const screen = useWindowMediaQuery();
	return (
		<MarketingCompStyled screen={screen}>
			{screen.lg && (
				<CurvesStyled>
					<img src={MarketingPageCurves} alt="" />
				</CurvesStyled>
			)}

			<MainContentText screen={screen}>
				<div>
					<h1>Modern Day Magic</h1>
					<h4>
						It's time to make your ad-budget count, scale your business and blow up
						your returns.
					</h4>
					<p>
						Our goal is to help Start Up’s as well as E-commerce & Info Product
						Businesses Produce Spine-Chilling ROI Via Paid Advertising
					</p>

					<ButtonStyled variant="dark" href="/contact" rel="noopener noreferrer" >
						Let's Talk <span className="material-icons">keyboard_arrow_right</span>
					</ButtonStyled>
				</div>
			</MainContentText>

			<WhatWeOfferSection />
			<AuditCallPerfectComp />
			<SpeakToTeamSection />
		</MarketingCompStyled>
	);
};

const MarketingCompStyled = styled.div``;

const CurvesStyled = styled.div`
	margin-top: 35px;
	position: relative;

	img {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
`;

const MainContentText = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 0 auto;
	max-width: var(--max-width-content);
	padding-top: ${({ screen }) =>
		screen.xl ? "175px" : screen.md ? "75px" : "50px"};
	margin-bottom: ${({ screen }) =>
		screen.xl ? "100px" : screen.md ? "130px" : "70px"};

	div {
		max-width: 835px;
	}

	h1 {
		font-weight: ${({ screen }) =>
			screen.xl ? "normal" : screen.md ? "700" : "normal"};
		font-size: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "50px" : "42px"};
		line-height: ${({ screen }) =>
			screen.xl ? "72px" : screen.md ? "64px" : "56px"};
		color: var(--bs-primary);
		margin-bottom: 11px;
	}

	h4 {
		max-width: 750px;
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 18px;
		color: var(--bs-primary);
	}

	p {
		max-width: 550px;
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: var(--bs-primary);
	}
`;

const ButtonStyled = styled(Button)`
	&.btn {
		margin-left: auto;
		margin-top: 30px;
		border-radius: 50px;
		width: 140px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding-left: 20px;
		background: var(--bs-gray-100);
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: var(--bs-secondary);
		border-color: var(--bs-gray-100);
	}
`;
