import styled from "styled-components";
import { Row, Card, Col } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

const cardsTexts = [
	{
		title: "Base Package",
		price: "$299",
		desc: ["1 Page Website", "1 Embedded Video", "Contact Form", "Complete Deployment"],
	},
	{
		title: "Startup Package",
		price: "$499",
		desc: ["3 Page Website", "2 Embedded Videos", "Contact Form at multiple locations", "Google Friendly Sitemap",  "Content writing and grammar check", "Complete Deployment"],
	},
	{
		title: "Value Package",
		price: "$999",
		desc: ["5 Page Website", "4 Embedded Videos", "Contact Form at multiple locations", "Google Friendly Sitemap", "Chatbot Integration", "Professional Content writing", "Complete Deployment"],
	},
	{
		title: "Professional Package",
		price: "$1500",
		desc: ["7 Page Website", "Unlimited Embedded Videos", "Contact Form at multiple locations", "Google Friendly Sitemap", "Chatbot Integration", "Additional 3rd Party Integrations", "Content Management Support", "Professional Content writing", "Complete Deployment"],
	},
	{
		title: "E-Commerce Package",
		price: "$2000",
		desc: ["Up-to 100 products", "User login and signup", "Shopping Cart Integration", "Payment Method Integration", "Chatbot Integration", "All 3rd Party Integrations", "Google Analytics", "Content Management System", "Professional Content writing", "Complete Deployment"],
	},
	{
		id: "custom",
		title: "Custom Package",
		price: "$Custom",
		desc: ["Contact us to make your own pack based on your needs"],
	},
];

export const PricingCards = () => {
	const screen = useWindowMediaQuery();

	return (
		<CardsStyled screen={screen}>
			<h3>{screen.md ? "Budget-Friendly Web Design Package" : "Budget-Friendly Package"} </h3>
			{screen.md && (
				<h6>
					Our pricing structures and packages are based on the needs of our clients.
					Our team of skilled designers and developers is capable of providing a wide range of breathtaking 
					layouts and fascinating designs that are affordable and within your budget.
				</h6>
			)}
			<p className="cad-currency">* All currency is in Canadian Dollars (CAD)</p>
			<Row xs={1} md={2} lg={3} className="price-card-spacing">
			{cardsTexts.map((card, index) => (
				<Col key={index} className="col-pricing">
					<Card className="pricing-cards">
						<Card.Body>
						<Card.Title>{card.title}</Card.Title>
						<Card.Title className="price">{card.price}</Card.Title>
						<Card.Text>
						{card.id!=="custom"?
							<ul>
								{card.desc.map((whatsincluded)=>(
									<li className="whatsincluded-style"><span className="material-icons">check</span>{whatsincluded}</li>
								))}
							</ul>: <p className="custom-pricing-desc">{card.desc}</p>
						}
						</Card.Text>
						</Card.Body>
						<Card.Footer>
							
						</Card.Footer>
					</Card>
				</Col>
			))}
			</Row>
		</CardsStyled>
	);
};

const CardsStyled = styled(Row)`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
	margin: ${({ screen }) => (screen.md ? "0 auto" : "20px auto")};
	margin-bottom: ${({ screen }) => (screen.md ? "120px" : "100px")};

	h6 {
		font-size: 18px;
		line-height: 32px;
		font-weight: 400;
		color: var(--bs-primary);
		margin-bottom: 40px;
	}

	h3 {
		font-size: ${({ screen }) =>
			screen.xl ? "40px" : screen.md ? "30px" : "30px"};
		font-weight: bold;
		line-height: ${({ screen }) =>
			screen.xl ? "60px" : screen.md ? "56px" : "42px"};
		color: var(--bs-primary);
		margin-bottom: 10px;
	}
	
	ul{
		list-style: none;
		padding-left: 0px;
		font-size: 15px;
	}
	
	li{
		display: flex;
		span{
			padding-right: 7px;
		}
	}

	.cad-currency{
		font-size: 12px;
		font-weight: 200;
		padding-bottom: 5px;
	}

	.price {
		font-size: 40px;
	}

	.pricing-cards{
		height: 100%
	}
	
	.col-pricing{
		padding-bottom: 20px;
	}

	.custom-pricing-desc{
		padding-top: 20px;
	}

	.whatsincluded-style{
		margin-top: 15px;
	}
`;
