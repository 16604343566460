import { FooterComp } from "../Components/Shared/Footer/Footer";
import { WaapHeaderComp } from "../Components/Shared/Header/WaapHeader";

const WaapCustomLayout = ({ children }) => {
	return (
		<>
			<WaapHeaderComp />
			{children}
			<FooterComp />
		</>
	);
};

export default WaapCustomLayout;
