import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { WhoWeAreImage } from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const WhoAreWeSection = () => {
	const screen = useWindowMediaQuery();
	return (
		<WhoAreWeSectionStyled screen={screen}>
			<h6>Our Team</h6>
			<h3>Who We Are</h3>

			<Row>
				<Col className="about-image" md={5}>
					<img src={WhoWeAreImage} alt="" />
				</Col>
				<Col className="about-content" md={7}>
					<p>
						Explorator is an end-to-end web product agency, focusing on designing and
						engineering today’s mobile and web businesses and new ventures.
					</p>
					<p>
						Founded by digital leaders and technologists with an extensive background
						in web development, Explorator is built by folks that have been on the
						other side of the table and also have extensive entrepreneurial
						experience.
					</p>
					<p>
						We treat every project as if it were our own, from prototype development
						and startup ventures to bule-chip brands.
					</p>
					<p>
						We’re fanatical about helping our clients stay a step ahead. Our veteran
						team of designers and engineers have deep experience in everything and our
						process ensures we build web products that create measurable value on time
						and on budget.
					</p>
				</Col>
			</Row>
		</WhoAreWeSectionStyled>
	);
};

const WhoAreWeSectionStyled = styled(Row)`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
	margin: 0 auto;
	margin-bottom: ${({ screen }) => (screen.md ? "160px" : "80px")};

	h6 {
		font-size: 24px;
		line-height: 28px;
		font-weight: normal;
		color: var(--bs-primary);
	}

	h3 {
		font-size: ${({ screen }) =>
			screen.xl ? "56px" : screen.md ? "42px" : "32px"};
		line-height: ${({ screen }) =>
			screen.xl ? "66px" : screen.md ? "56px" : "42px"};
		font-weight: bold;
		color: var(--bs-primary);
		margin-bottom: ${({ screen }) => (screen.md ? "50px" : "25px")};
	}

	.about-image {
		img {
			width: 100%;
		}
	}

	.about-content {
		padding: ${({ screen }) => (screen.xl ? "0 32px" : "0 16px")};

		p {
			font-weight: normal;
			font-size: 18px;
			line-height: 30px;
			margin-top: ${({ screen }) => (screen.md ? "0" : "30px")};
			margin-bottom: 30px;
		}
	}
`;
