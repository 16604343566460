import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { routes } from "../../routes";
import ReactGA from 'react-ga';

function Root() {
	useEffect(() => {
		if(window.location.host === "exploratorlabs.com"){
			ReactGA.initialize('UA-135625196-1');
			ReactGA.pageview(window.location.pathname);
		}
	});

	return (
		<div id="App">
			<Switch>
				{routes.map((route) => (
					<Route
						key={route.name}
						exact
						path={route.path}
						component={route.component}
					/>
				))}
				<Route path="*">
					<Redirect to="/" />
				</Route>
			</Switch>
		</div>
	);
}

export default Root;
