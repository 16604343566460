import styled from "styled-components";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";
import {
	Number_1,
	Number_2,
	Number_3,
	Number_4,
} from "../../Utils/ImageImportFile";

export const AuditCallPerfectComp = () => {
	const screen = useWindowMediaQuery();
	return (
		<AuditCallStyled screen={screen}>
			<h2>This Audit Call Is Perfect For...</h2>

			<UpperAuditBox className="d-flex flex-md-row flex-column" screen={screen}>
				<div className="audit-box">
					<div>
						<img src={Number_1} alt="" />
					</div>
					<p>
						Businesses looking to convert their current website into a high quality &
						streamlined funnel format.
					</p>
				</div>
				<div className="audit-box">
					<div>
						<img style={{ maxHeight: "120px" }} src={Number_2} alt="" />
					</div>
					<p>
						Start Ups or Businesses looking to take their offline business online.
					</p>
				</div>
			</UpperAuditBox>

			<BottomAuditBox
				className="d-flex justify-content-end flex-md-row flex-column"
				screen={screen}>
				<div className="audit-box">
					<div>
						<img src={Number_3} alt="" />
					</div>
					<p>
						Businesses looking to understand their increased revenue potential with
						funnels & conversion rate optimization.
					</p>
				</div>
				<div className="audit-box">
					<div>
						<img src={Number_4} alt="" />
					</div>
					<p>
						Businesses looking to maximize their conversion rates & average order
						value and for a company that can make their company a priority
					</p>
				</div>
			</BottomAuditBox>
		</AuditCallStyled>
	);
};

const AuditCallStyled = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 0 auto;
	margin-bottom: 130px;
	max-width: var(--max-width-content);

	h2 {
		font-weight: bold;
		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "42px" : "24px"};
		line-height: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "56px" : "32px"};
		color: var(--bs-primary);
		margin-bottom: 50px;
	}
`;

const UpperAuditBox = styled.div`
	.audit-box {
		display: flex;
		align-items: center;
		max-width: ${({ screen }) => (screen.md ? "340px" : "100%")};
		margin-right: ${({ screen }) =>
			screen.lg ? "24%" : screen.md ? "18%" : "0"};
		margin-bottom: ${({ screen }) => (screen.md ? "unset" : "15px")};

		p {
			margin-left: ${({ screen }) => (screen.md ? "-30px" : "15px")};
			font-weight: normal;
			font-size: 16px;
			line-height: 28px;
			color: var(--bs-primary);
		}

		> div {
			max-width: ${({ screen }) => (screen.md ? "unset" : "100px")};
			width: ${({ screen }) => (screen.md ? "unset" : "100px")};
			img {
				width: inherit;
				max-height: ${({ screen }) => (screen.md ? "" : "200px")};
			}
		}
	}
`;

const BottomAuditBox = styled.div`
	.audit-box {
		display: flex;
		align-items: center;
		max-width: ${({ screen }) => (screen.md ? "340px" : "100%")};
		margin-left: ${({ screen }) => (screen.xl ? "24%" : screen.md ? "10%" : "0")};
		margin-bottom: ${({ screen }) => (screen.md ? "unset" : "15px")};

		p {
			margin-left: ${({ screen }) => (screen.md ? "-40px" : "15px")};
			font-weight: normal;
			font-size: 16px;
			line-height: 28px;
			color: var(--bs-primary);
		}

		> div {
			max-width: ${({ screen }) => (screen.md ? "unset" : "100px")};
			width: ${({ screen }) => (screen.md ? "unset" : "100px")};
			img {
				width: inherit;
				max-height: ${({ screen }) => (screen.md ? "" : "200px")};
			}
		}
	}
`;
