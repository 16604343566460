import styled from "styled-components";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const TechStackContainer = ({ imagesList }) => {
	const screen = useWindowMediaQuery();
	return (
		<TechStackStyled screen={screen}>
			<h4>&nbsp;</h4>
			<h2>Tech Stack Used</h2>
			<div className="d-flex flex-wrap">
				{imagesList.map((image, index) => (
					<img key={index} src={image} alt="" />
				))}
			</div>
		</TechStackStyled>
	);
};

const TechStackStyled = styled.div`
	h4 {
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		color: var(--bs-primary);
	}

	h2 {
		font-weight: ${({ screen }) =>
			screen.xl ? "bold" : screen.md ? "normal" : "bold"};
		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "40px" : "24px"};
		line-height: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "52px" : "42px"};
		color: var(--bs-primary);
		margin-bottom: 12px;
	}

	img {
		max-height: 65px;
		margin-right: ${({ screen }) => (screen.xl ? "35px" : "25px")};
		margin-bottom: 10px;
	}
`;
