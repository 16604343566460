import styled from "styled-components";
import { Row} from "react-bootstrap";
import { DesignCatalogCards } from "./CatalogCards";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const DesignCatalog = () => {
	const screen = useWindowMediaQuery();
	return (
		<DesignCatalogSectionStyled screen={screen}>
			<h3>{screen.md ? "Website Portfolio" : "Website Portfolio"} </h3>
			{screen.md && (
				<h6>
					Check out our website development solutions across various industries 
					to find your best fit for a stunning website that we can create for you.
				</h6>
			)}
			<DesignCatalogCards/>
		</DesignCatalogSectionStyled>
	);
};

const DesignCatalogSectionStyled = styled(Row)`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
	margin: ${({ screen }) => (screen.md ? "0 auto" : "20px auto")};
	margin-bottom: ${({ screen }) => (screen.md ? "120px" : "100px")};

	h6 {
		font-size: 20px;
		line-height: 32px;
		font-weight: 400;
		color: var(--bs-primary);
		margin-bottom: 50px;
	}

	h3 {
		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "42px" : "32px"};
		font-weight: bold;
		line-height: ${({ screen }) =>
			screen.xl ? "60px" : screen.md ? "56px" : "42px"};
		color: var(--bs-primary);
		margin-bottom: 10px;
	}

	.tab-text{
		color: var(--bs-primary);
	}
`;
