import styled from "styled-components";
import { Row, Col, Button } from "react-bootstrap";

import { ProductDetailContainer } from "./ProductDetail";
import { ProjectScobeContainer } from "../../Shared/CaseStudiesComp/ProjectScobe";
import { ProblemOverviewContainer } from "./ProblemOverview";
import { TechOutcomeContainer } from "./TechOutcome";
import { BusinessOutcomeContainer } from "./BusinessOutcome";
import { SpeakToTeamSection } from "./SpeakToTeam";
import { TechStackContainer } from "../../Shared/CaseStudiesComp/TechStack";

import {
	ottawaCaseStudy,
	pivotCaseStudy,
	floatCaseStudy,
	globalPassCaseStudy,
	oshCaseStudy,
	waveRoomCaseStudy,
} from "../../Utils/caseStudyData";
import { useParams } from "react-router";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const CaseStudyComponent = () => {
	const screen = useWindowMediaQuery();
	const { organization } = useParams();
	let caseStudy;
	if (organization === "pivot") {
		caseStudy = pivotCaseStudy;
	} else if (organization === "float") {
		caseStudy = floatCaseStudy;
	} else if (organization === "globalPass") {
		caseStudy = globalPassCaseStudy;
	} else if (organization === "offshorehires") {
		caseStudy = oshCaseStudy;
	} else if (organization === "waveroom") {
		caseStudy = waveRoomCaseStudy;
	}
	else {
		caseStudy = ottawaCaseStudy;
	}
	console.log(caseStudy.site_link);

	return (
		<CaseStudyStyled>
			<HeroSectionStyled
				background={caseStudy.backgroundGradientColor}
				screen={screen}>
				<Row className="content-hero-section justify-content-between">
					{screen.md && (
						<Col
							md={6}
							className="text-content-col d-flex flex-column justify-content-center">
							{caseStudy === oshCaseStudy && (
								<img src={caseStudy.logo} className="img-width-osh" alt="" />
							)}
							{caseStudy === waveRoomCaseStudy && (
								<img src={caseStudy.logo} className="img-width-wavroom" alt="" />
							)}
							{caseStudy !== oshCaseStudy && caseStudy !== waveRoomCaseStudy && (
								<img src={caseStudy.logo} alt="" />
							)}
							<h5>{caseStudy.headerParaText}</h5>
							<ButtonStyled screen={screen} variant="outline-dark">
								<a href={caseStudy.site_link} rel="noreferrer" target="_blank">
									Check Website
								</a>
							</ButtonStyled>
						</Col>
					)}

					<Col md={6} className="main-image-container text-end">
						<img className="img-fluid" src={caseStudy.heroImage} alt="" />
					</Col>
				</Row>
			</HeroSectionStyled>

			<WrapperContainer screen={screen}>
				{!screen.md && (
					<Col
						md={6}
						className="text-content-col d-flex flex-column justify-content-center">
						{caseStudy === waveRoomCaseStudy && (
							<img src={caseStudy.logo} className="img-width-wavroom" alt="" />
						)}
						{caseStudy !== waveRoomCaseStudy && (
							<img src={caseStudy.logo} alt="" />
						)}
						<h5>{caseStudy.headerParaText}</h5>
						<ButtonStyled variant="outline-dark" screen={screen}>
							<a href={caseStudy.site_link} rel="noreferrer" target="_blank">
								Check Website
							</a>
						</ButtonStyled>
					</Col>
				)}
				<ProductDetailContainer
					client={caseStudy.client}
					timeline={caseStudy.timeline}
					serviceType={caseStudy.serviceType}
					platform={caseStudy.platform}
				/>

				<Row className="justify-content-between">
					<Col lg={7} md={6}>
						<ProjectScobeContainer text={caseStudy.projectScobeText} />
					</Col>
					{caseStudy.techStack && (
						<Col lg={5} md={6}>
							<TechStackContainer imagesList={caseStudy.techStack} />
						</Col>
					)}
				</Row>

				<ProblemOverviewContainer
					data={caseStudy.problemOverview}
					image={caseStudy.problemOverviewImg}
				/>
				<TechOutcomeContainer
					caseStudy={caseStudy}
					data={caseStudy.techOutcomeText}
					image={caseStudy.techOutcomeImage}
					points={caseStudy.techOutcomeList}
				/>
				<BusinessOutcomeContainer
					data={caseStudy.businessOutcomeText}
					image={caseStudy.businessOutcomeImage}
				/>
				<SpeakToTeamSection />
			</WrapperContainer>
		</CaseStudyStyled>
	);
};

const CaseStudyStyled = styled.div``;

const HeroSectionStyled = styled.div`
	padding: ${({ screen }) => (screen.xl ? "79px 0" : "38px 0")};
	background: ${({ background }) => background};
	.content-hero-section {
		width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
		margin: 0 auto;
		max-width: var(--max-width-content);

		.text-content-col {
			max-width: 500px;
			img {
				width: 150px;
			}
			.img-width-osh{
				width: 250px;
				max-height: 90px;
			}
			.img-width-wavroom{
				width: 100px;
			}
			h5 {
				margin-top: 24px;
				margin-bottom: 11px;
				font-weight: normal;
				font-size: 18px;
				line-height: 30px;
				color: var(--bs-gray-100);
			}
		}
	}

	.main-image-container {
		img {
			/* max-width: 660px; */
			/* width: -webkit-fill-available; */
		}
	}
`;

const ButtonStyled = styled(Button)`
	&.btn {
		margin-left: auto;
		margin-top: ${({ screen }) => (screen.md ? "30px" : "10px")};
		border-radius: 50px;
		width: 140px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;

		a {
			text-decoration: none;
			color: inherit;
		}
	}
`;

const WrapperContainer = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 0 auto;
	max-width: var(--max-width-content);
	
	.text-content-col {
		align-items: flex-start;
		max-width: 500px;
		img {
			margin-top: 17px;
			margin-bottom: 5px;
			width: 150px;
		}
		.img-width-wavroom{
			width: 100px;
		}
		h5 {
			margin-bottom: 11px;
			font-weight: normal;
			font-size: 16px;
			line-height: 28px;
			color: var(--bs-gray-100);
		}
	}
`;
