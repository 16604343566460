import styled from "styled-components";
import { Row, Col, Button } from "react-bootstrap";
import { FieldsContentSection } from "./FieldsContent";
import { ApproachPageCurves } from "../../Utils/ImageImportFile";
import { PortfolioSection } from "./Portfolio";
import { LetsTalkComponent } from "./LetsTalk";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const ApproachPageComponent = () => {
	const screen = useWindowMediaQuery();
	return (
		<ApproachCompStyled>
			<MainContentText screen={screen}>
				<h1>A very effective process</h1>
				<Row className="elegant-solution">
					<Col md={9}>
						<h4>Elegant solutions built on proven methodologies</h4>
						<p>
							Our team builds a symbiotic relationship with each partners and client
							in order to design and develop their desired custom mobile apps,
							platforms and experiences.
						</p>
					</Col>
					<Col md={3} className="align-self-end">
						<ButtonStyled variant="dark" href="/contact" rel="noopener noreferrer" >
							Let's Talk <span className="material-icons">keyboard_arrow_right</span>
						</ButtonStyled>
					</Col>
				</Row>
			</MainContentText>

			<FieldsContentSection />
			<div>
				<CurvesStyled>
					<img src={ApproachPageCurves} alt="" />
				</CurvesStyled>
			</div>

			<PortfolioSection />

			<LetsTalkComponent />
		</ApproachCompStyled>
	);
};

const ApproachCompStyled = styled.div`
	position: relative;
`;

const MainContentText = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: ${({ screen }) => (screen.xl ? "200px auto" : "90px auto")};
	margin-bottom: ${({ screen }) => (screen.xl ? "150px" : "91px")};
	max-width: var(--max-width-content);

	h1 {
		font-weight: ${({ screen }) =>
			screen.xl ? "normal" : screen.md ? "bold" : "normal"};
		font-size: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "50px" : "42px"};
		line-height: ${({ screen }) =>
			screen.xl ? "72px" : screen.md ? "64px" : "50px"};
		color: var(--bs-primary);
		margin-bottom: 11px;
	}

	.elegant-solution {
		max-width: 880px;

		h4 {
			font-weight: normal;
			font-size: ${({ screen }) => (screen.md ? "24px" : "18px")};
			line-height: ${({ screen }) => (screen.md ? "32px" : "24px")};
			margin-bottom: 18px;
			color: var(--bs-primary);
		}

		p {
			max-width: 550px;
			font-weight: normal;
			font-size: 16px;
			line-height: 28px;
			color: var(--bs-primary);
		}
	}
`;

const ButtonStyled = styled(Button)`
	&.btn {
		margin-left: auto;
		margin-top: 30px;
		border-radius: 50px;
		width: 140px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding-left: 20px;
		background: var(--bs-gray-100);
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: var(--bs-secondary);
		border-color: var(--bs-gray-100);
	}
`;

const CurvesStyled = styled.div`
	position: relative;

	img {
		position: absolute;
		top: -180px;
		right: 0;
		z-index: -1;
	}
`;
