import styled from "styled-components";
import { Row } from "react-bootstrap";
import {
	BoxiaLogo,
	FloatLogo,
	GlobalPassLogo,
	DattwaLogo,
	PivotLogo,
} from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const FormerClients = () => {
	const screen = useWindowMediaQuery();
	return (
		<FormerClientsStyled screen={screen}>
			<h6>Former Clients</h6>
			<h3>Clients</h3>

			<SliderImageContainer screen={screen}>
				<div>
					<img src={DattwaLogo} alt="" />
					<img src={FloatLogo} alt="" />
					<img src={PivotLogo} alt="" />
					<img src={BoxiaLogo} alt="" />
					<img src={GlobalPassLogo} alt="" />
				</div>
			</SliderImageContainer>
		</FormerClientsStyled>
	);
};

const FormerClientsStyled = styled(Row)`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
	margin: 0 auto;
	margin-bottom: 100px;

	h6 {
		font-size: 24px;
		line-height: 32px;
		font-weight: normal;
		color: var(--bs-primary);
	}

	h3 {
		font-weight: bold;
		font-size: ${({ screen }) => (screen.xl ? "50px" : "42px")};
		line-height: ${({ screen }) => (screen.xl ? "60px" : "56px")};
		color: var(--bs-primary);
		margin-bottom: 17px;
	}
`;

const SliderImageContainer = styled.div`
	margin: 30px 0;

	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px;
		background: #f8f9fa;
		gap: 120px;
		overflow-x: scroll;

		img {
			width: 9.5rem;
			height: auto;
		}
	}
`;
