import styled from "styled-components";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";
import { 
	Example, 
	DC_1_XPLetsPartner_Cover, 
	DC_1_XPLetsPartner_1, 
	DC_1_XPLetsPartner_2, 
	DC_1_XPLetsPartner_3,
	Techmart_Scroll,
	Techmart_Cover,
	Planet_Scroll,
	Planet_Cover,
	Camp_Scroll,
	Camp_Cover,
	Antic_Scroll,
	Antic_Cover,
	Affogato_Scroll,
	Affogato_Cover
	} from "../../Utils/ImageImportFile";
import React, { useState } from 'react';

const cardsTexts = [
		{
			coverImage: Techmart_Cover,
			title: "Tech Mart",
			desc: "Electronics Shop with a brand",
			images: [Techmart_Scroll],
		},
		{
			coverImage: Planet_Cover,
			title: "Planet Plant",
			desc: "Save Environment with slick design",
			images: [Planet_Scroll],
		},
		{
			coverImage: Camp_Cover,
			title: "Camp Hub",
			desc: "Promote Camping and grow your business",
			images: [Camp_Scroll],
		},
		{
			coverImage: Antic_Cover,
			title: "Antic Design",
			desc: "Grow your Tourism business",
			images: [Antic_Scroll],
		},
		{
			coverImage: Affogato_Cover,
			title: "Affogato",
			desc: "Showcase your Authentic food",
			images: [Affogato_Scroll],
		},	
		{
			coverImage: DC_1_XPLetsPartner_Cover,
			title: "Landing Page",
			desc: "Tell the world about your business",
			images: [DC_1_XPLetsPartner_1, DC_1_XPLetsPartner_2, DC_1_XPLetsPartner_3],
		},
	];

export const DesignCatalogCards = () => {
	const screen = useWindowMediaQuery();
	const [show, setShow] = useState(false);
	const [index, setIndex] = useState(0);

	return (
		<CatalogCardStyled screen={screen}>
			<Row xs={1} md={2} lg={3} className="g-4">
			{cardsTexts.map((card, index) => (
				<Col key={index} className="no-left-padding">
				<Button variant="light" onClick={() => {setShow(true); setIndex(index)}}>
				<Card>
					<Card.Img variant="top" src={card.coverImage} />
					<Card.Body className="catalog-card-body">
					<Card.Title className="catalog-card-title">
						{card.title}
						<Button variant="outline-dark" className="catalog-view-button" onClick={() => {setShow(true); setIndex(index)}}>View</Button>
					</Card.Title>
					<Card.Text>
						{card.desc}
					</Card.Text>
					</Card.Body>
				</Card>
				</Button>
				</Col>	
			))}
			</Row>
			
			<Modal show={show} dialogClassName="modal-90w" onHide={() => setShow(false)}>
				<Modal.Header closeButton>
				<Modal.Title className="modal-title">{cardsTexts[index].title}</Modal.Title>
				</Modal.Header>
				{cardsTexts[index].images.map((image, index) => (
					<Modal.Body key={index}>
							<img className="modal-image-size" alt="Loading..." src={image}></img>
					</Modal.Body>
				))}
			</Modal>
		</CatalogCardStyled>
	);
};

const CatalogCardStyled = styled.div`
	.modal-image-size{
		width: 100%;
	}

	.modal-title{
		font-size: 20px;
		font-weight: 400;
	}

	.modal-90w{
		width: 90%;
		max-width: unset;
	}

	.catalog-card-body{
		text-align: left;
		font-size: 0.7em;
	}

	.catalog-card-title{
		font-size: 1.4em;
		display: flex;
		justify-content: space-between;
	}

	.catalog-view-button{
		line-height: 0.7;
		font-size: 0.7em;
	}

	.no-left-padding{
		padding-left: 0px;
	}
`;