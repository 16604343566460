import styled from "styled-components";
import { Row } from "react-bootstrap";
import { CustomFormWaap } from "../../Shared/Form/Waap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const CallWithTeam = () => {
	const screen = useWindowMediaQuery();
	return (
		<CallWithTeamStyled screen={screen} id="contact">
			<WrapperContainer screen={screen}>
				<h6>Lets Talk</h6>
				<h2>Schedule Your Call With The Team</h2>

				<p>
					We’ve worked with clients of all sizes, from enterprise brands to funded
					startups. Let’s talk about your project and how we can help provide value.
				</p>
				<p>Tell us about your project. What problem can we help you solve?</p>
			</WrapperContainer>

			<FormStyled screen={screen}>
				<CustomFormWaap />
			</FormStyled>
		</CallWithTeamStyled>
	);
};

const CallWithTeamStyled = styled(Row)`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
	margin: 0 auto;
	margin-bottom: ${({ screen }) => (screen.md ? "160px" : "80px")};

	h6 {
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		color: var(--bs-primary);
	}

	h2 {
		font-weight: bold;
		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "42px" : "24px"};
		line-height: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "56px" : "42px"};
		color: var(--bs-primary);
		margin-bottom: ${({ screen }) => (screen.md ? "35px" : "15px")};
	}
`;

const WrapperContainer = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};

	p {
		margin-bottom: 30px;
	}
`;

const FormStyled = styled.div`
	width: ${({ screen }) => (screen.md ? "90%" : "100%")};
	margin-bottom: 75px;
`;
