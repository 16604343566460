import styled from "styled-components";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";
import { QuotationMark1, QuotationMark2 } from "../../Utils/ImageImportFile";
import { AboutUsComponent } from "./AboutUsSection";
import { FieldsIntroSection } from "./FieldsIntro";
import { MeetYourExpertsSection } from "./MeetYourExperts";
import { WhatWeDoSection } from "./WhatWeDo";

export const AboutPageComponent = () => {
	const screen = useWindowMediaQuery();
	return (
		<AboutCompStyled screen={screen}>
			<MainContentText screen={screen}>
				{screen.md ? (
					<h1>
						<img
							className="starting-quote"
							src={QuotationMark1}
							width="74px"
							height="74px"
							alt=""
						/>
						{"  "}We’re a growing team of mobile and digital experts, fanatical about
						helping you succeed &nbsp;
						<img
							className="ending-quote"
							src={QuotationMark2}
							width="74px"
							height="74px"
							alt=""
						/>
					</h1>
				) : (
					<div className="">
						<img
							className="starting-quote"
							src={QuotationMark1}
							width="74px"
							height="74px"
							alt=""
						/>
						<h1>
							{"  "}We’re a growing team of mobile and digital experts, fanatical about
							helping you succeed &nbsp;
						</h1>
						<img
							style={{ float: "right" }}
							className="ending-quote"
							src={QuotationMark2}
							width="74px"
							height="74px"
							alt=""
						/>
					</div>
				)}
			</MainContentText>

			<AboutUsComponent />
			<WhatWeDoSection />
			<FieldsIntroSection />
			<MeetYourExpertsSection />
		</AboutCompStyled>
	);
};

const AboutCompStyled = styled.div`
	position: relative;
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 80px auto;
	margin-bottom: 50px;
	max-width: var(--max-width-content);
`;

const MainContentText = styled.div`
	margin: 100px auto;
	h1 {
		max-width: 836px;
		margin: 0 auto;
		position: relative;
		text-align: center;
		font-weight: normal;
		font-size: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "42px" : "34px"};
		line-height: ${({ screen }) =>
			screen.xl ? "72px" : screen.md ? "56px" : "42px"};
		color: var(--bs-primary);

		.starting-quote {
			margin-top: -50px;
		}
	}
`;
