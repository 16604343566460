import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

const cardsTexts = [
	{
		title: "Custom Design",
		desc:
			"We craft smart solutions with research-backed strategies and high-quality, professional-grade custom product designs. We're fanatical about the UX/UI design process, and we go deep to cover all user scenarios and edge cases. We get noticed for our brand design, too.",
	},
	{
		title: "Scalable Applications",
		desc:
			"We architect and code Web, iOS and Android mobile apps, built for scalability and security. We don't shy away from building custom web platforms either. Our product engineering folks are industry veterans from leading technology organizations. We’ve seen it all.",
	},
	{
		title: "Launch & Growth",
		desc:
			"We know how to get you to the starting line on time and within budget, but we don’t stop there. We’re strong believers in executing ongoing, fluid Agile product releases using informed decisions based on measured learnings from real users. Launching is just the beginning.",
	},
];

export const LandingPageServiceCards = () => {
	const screen = useWindowMediaQuery();

	return (
		<CardsStyled screen={screen}>
			{!screen.md && <h4>What we do</h4>}
			{cardsTexts.map((card, index) => (
				<Col md={4} key={index}>
					<h5>{card.title}</h5>

					<p>{card.desc}</p>
				</Col>
			))}
		</CardsStyled>
	);
};

const CardsStyled = styled(Row)`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
	margin: ${({ screen }) => (screen.md ? "160px auto" : "100px auto")};

	h4 {
		font-weight: bold;
		font-size: ${({ screen }) => (screen.xl ? "50px" : "28px")};
		line-height: ${({ screen }) => (screen.xl ? "64px" : "42px")};
		margin-bottom: 10px;
	}

	h5 {
		margin-top: ${({ screen }) => (screen.md ? "0" : "50px")};
		font-weight: ${({ screen }) => (screen.md ? "normal" : "bold")};
		font-size: ${({ screen }) => (screen.xl ? "28px" : "21px")};
		line-height: ${({ screen }) => (screen.xl ? "33px" : "26px")};
		margin-bottom: 30px;
		color: var(--bs-primary);
	}

	p {
		font-weight: normal;
		font-size: 14px;
		line-height: 25px;
		color: var(--bs-primary);
	}
`;
