import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const ProductDetailContainer = ({
	client,
	timeline,
	serviceType,
	platform,
}) => {
	const screen = useWindowMediaQuery();
	return (
		<ProductDetailStyled className="justify-content-between" screen={screen}>
			<Col md={2} xs={6}>
				<HeadingStyled screen={screen}>Client</HeadingStyled>
				{client.map((clientText, index) => (
					<TextStyled screen={screen} key={index}>
						{clientText}
					</TextStyled>
				))}
			</Col>
			<Col md={2} xs={6}>
				<HeadingStyled screen={screen}>Timeline</HeadingStyled>
				{timeline.map((timelineText, index) => (
					<TextStyled screen={screen} key={index}>
						{timelineText}
					</TextStyled>
				))}
			</Col>
			<Col md={4}>
				<HeadingStyled screen={screen}>Service Type</HeadingStyled>
				{serviceType.map((serviceText, index) => (
					<TextStyled screen={screen} key={index}>
						{serviceText}
					</TextStyled>
				))}
			</Col>
			<Col md={3}>
				<HeadingStyled screen={screen}>Platform</HeadingStyled>
				{platform.map((platformText, index) => (
					<TextStyled screen={screen} key={index}>
						{platformText}
					</TextStyled>
				))}
			</Col>
		</ProductDetailStyled>
	);
};

const ProductDetailStyled = styled(Row)`
	margin-top: ${({ screen }) => (screen.md ? "65px" : "30px")};
	margin-bottom: 110px;
`;

const HeadingStyled = styled.h4`
	font-weight: ${({ screen }) => (screen.md ? "normal" : "bold")};
	font-size: ${({ screen }) => (screen.md ? "16px" : "24px")};
	line-height: ${({ screen }) => (screen.md ? "30px" : "42px")};
	margin-bottom: ${({ screen }) => (screen.md ? "30px" : "2px")};
	margin-top: ${({ screen }) => (screen.md ? "0" : "16px")};
	color: #000;
`;
const TextStyled = styled.p`
	font-weight: normal;
	font-size: ${({ screen }) => (screen.xl ? "20px" : "18px")};
	line-height: ${({ screen }) => (screen.xl ? "32px" : "30px")};
	color: #000;
`;
