import { useState } from "react";
import styled from "styled-components";
import { Row, Col, Button, Form, Alert } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

import emailjs from "emailjs-com";

import { useFormik } from "formik";
import * as yup from "yup";

export const CustomForm = () => {
	const [showAlert, setShowAlert] = useState(false);
	const screen = useWindowMediaQuery();

	const validationSchema = yup.object().shape({
		firstName: yup.string().required("First name is required."),
		lastName: yup.string().required("Last name is required."),
		email: yup
			.string()
			.email("Enter valid email address.")
			.required("Email is required."),
		phone: yup.string().required("Phone number is required."),
		companyName: yup.string(),
		budget: yup
			.string()
			.notOneOf(["choose..."], "Select a your budget range.")
			.required("Approximate budget field is required."),
	});

	// Create .env files for the following variables from the Keys in Email.JS

	const formSubmit = (values) => {
		console.log("---form--values", values);
		emailjs
			.send(
				process.env.REACT_APP_EMAIL_SERVICE_ID,
				process.env.REACT_APP_EMAIL_TEMPLATE_ID,
				values,
				process.env.REACT_APP_EMAIL_USER_ID,
			)
			.then(
				(result) => {
					setShowAlert(true);
					formik.resetForm();
					console.log(result.text, result);
				},
				(error) => {
					console.log(error.text, error);
				},
			);

		setTimeout(() => {
			setShowAlert(false);
		}, 5000);
	};

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			companyName: "",
			budget: "choose...",
			message: "",
		},
		validationSchema: validationSchema,
		onSubmit: formSubmit,
	});

	return (
		<FormStyled screen={screen}>
			<Alert
				variant={"info"}
				show={showAlert}
				onClose={() => setShowAlert(false)}
				dismissabl>
				Email sent successfully!
			</Alert>
			<Form onSubmit={formik.handleSubmit}>
				<Row>
					<Form.Group as={Col} md={6} controlId="formGridFirstName">
						<FormLabelStyled
							error={formik.touched.firstName && Boolean(formik.errors.firstName)}>
							First Name
						</FormLabelStyled>
						{formik.touched.firstName && Boolean(formik.errors.firstName) && (
							<span class="material-icons">error</span>
						)}{" "}
						<Form.Control
							name="firstName"
							value={formik.values.firstName}
							onChange={formik.handleChange}
							isInvalid={formik.touched.firstName && Boolean(formik.errors.firstName)}
							type="text"
							placeholder="Please enter your first name"
						/>
						<Form.Control.Feedback type="invalid">
							{formik.errors.firstName}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group as={Col} md={6} controlId="formGridLastName">
						<FormLabelStyled
							error={formik.touched.lastName && Boolean(formik.errors.lastName)}>
							Last Name
						</FormLabelStyled>
						{formik.touched.lastName && Boolean(formik.errors.lastName) && (
							<span class="material-icons">error</span>
						)}{" "}
						<Form.Control
							name="lastName"
							value={formik.values.lastName}
							onChange={formik.handleChange}
							isInvalid={formik.touched.lastName && Boolean(formik.errors.lastName)}
							type="text"
							placeholder="Please enter your last name"
						/>
						<Form.Control.Feedback type="invalid">
							{formik.errors.lastName}
						</Form.Control.Feedback>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group as={Col} md={6} controlId="formGridEmail">
						<FormLabelStyled
							error={formik.touched.email && Boolean(formik.errors.email)}>
							Email
						</FormLabelStyled>
						{formik.touched.email && Boolean(formik.errors.email) && (
							<span class="material-icons">error</span>
						)}{" "}
						<Form.Control
							name="email"
							value={formik.values.email}
							onChange={formik.handleChange}
							isInvalid={formik.touched.email && Boolean(formik.errors.email)}
							type="email"
							placeholder="Please enter your email"
						/>
						<Form.Control.Feedback type="invalid">
							{formik.errors.email}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group as={Col} md={6} controlId="formGridPhone">
						<FormLabelStyled
							error={formik.touched.phone && Boolean(formik.errors.phone)}>
							Phone
						</FormLabelStyled>
						{formik.touched.phone && Boolean(formik.errors.firstName) && (
							<span class="material-icons">error</span>
						)}{" "}
						<Form.Control
							name="phone"
							value={formik.values.phone}
							onChange={formik.handleChange}
							isInvalid={formik.touched.phone && Boolean(formik.errors.phone)}
							type="text"
							placeholder="Please enter your phone"
						/>
						<Form.Control.Feedback type="invalid">
							{formik.errors.phone}
						</Form.Control.Feedback>
					</Form.Group>
				</Row>

				<Row>
					<Form.Group as={Col} md={6} controlId="formGridFirstName">
						<FormLabelStyled
							error={formik.touched.companyName && Boolean(formik.errors.companyName)}>
							Company Name
						</FormLabelStyled>
						{formik.touched.companyName && Boolean(formik.errors.companyName) && (
							<span class="material-icons">error</span>
						)}{" "}
						<Form.Control
							name="companyName"
							value={formik.values.companyName}
							onChange={formik.handleChange}
							isInvalid={
								formik.touched.companyName && Boolean(formik.errors.companyName)
							}
							type="text"
							placeholder="Please enter your company name"
						/>
						<Form.Control.Feedback type="invalid">
							{formik.errors.companyName}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group as={Col} md={6} controlId="formGridLastName">
						<FormLabelStyled
							error={formik.touched.budget && Boolean(formik.errors.budget)}>
							Budget
						</FormLabelStyled>
						{formik.touched.budget && Boolean(formik.errors.budget) && (
							<span class="material-icons">error</span>
						)}{" "}
						<Form.Select
							name="budget"
							value={formik.values.budget}
							onChange={formik.handleChange}
							isInvalid={formik.touched.budget && Boolean(formik.errors.budget)}
							placeholder="Approximate Budget">
							<option value="choose...">Choose...</option>
							<option value="< 5k">Less than $5,000</option>
							<option value="5k ~ 10k">$5,000 ~ $10,000</option>
							<option value="10k ~ 50k">$10,000 ~ $50,000</option>
							<option value="> 5k">More than $50,000</option>
						</Form.Select>
						<Form.Control.Feedback type="invalid">
							{formik.errors.budget}
						</Form.Control.Feedback>
					</Form.Group>
				</Row>

				<Row className="margin-bottom-40">
					<Form.Group as={Col} controlId="formGridFirstName">
						<FormLabelStyled>Message</FormLabelStyled>
						<Form.Control
							name="message"
							value={formik.values.message}
							onChange={formik.handleChange}
							className="text-area-message"
							as="textarea"
							rows={5}
							placeholder="Please tell us about your projects. How can we help you grow
							or what problem can we help you solve?"
						/>
					</Form.Group>
				</Row>

				<ButtonStyled type="submit" variant="dark" screen={screen}>
					Submit
				</ButtonStyled>
			</Form>
		</FormStyled>
	);
};

const FormStyled = styled.div`
	.row > div {
		position: relative;
		margin-bottom: ${({ screen }) => (screen.md ? "40px" : "20px")};
	}

	.material-icons {
		position: absolute;
		color: #ee0004;
		margin-top: ${({ screen }) => (screen.lg ? "4%" : screen.md ? "4%" : "2.5%")};
		right: ${({ screen }) => (screen.lg ? "5%" : screen.md ? "-2.5%" : "-1%")};
	}

	.form-control,
	.form-select {
		width: ${({ screen }) => (screen.lg ? "90%" : "95%")};
		padding: 15px 12px;
		background-color: #fbfbfb;
		border: 1px solid #dde2e5;
		box-sizing: border-box;
		border-radius: 4px;
	}

	.form-control:focus,
	.form-select:focus {
		border-color: var(--bs-gray-100);
		outline: 0;
		box-shadow: none;
	}

	.text-area-message {
		width: ${({ screen }) => (screen.lg ? "80%" : "98%")};
	}
	.form-select.is-invalid:not([multiple]):not([size]),
	.form-select.is-invalid:not([multiple])[size="1"],
	.was-validated .form-select:invalid:not([multiple]):not([size]),
	.was-validated .form-select:invalid:not([multiple])[size="1"] {
		background: none;
		border: 2px solid #ee0004;
	}

	.form-control.is-invalid,
	.was-validated .form-control:invalid {
		border: 2px solid #ee0004;
		background: none;
	}

	.invalid-feedback {
		color: #ee0004;
	}
`;

const FormLabelStyled = styled(Form.Label)`
	&.form-label {
		font-weight: normal;
		font-size: 14px;
		line-height: 21px;
		color: ${({ error }) => (error ? "#ee0004" : "var(--bs-gray-100)")};
	}
`;

const ButtonStyled = styled(Button)`
	&.btn {
		${({ screen }) => (screen.lg ? "" : "margin-right: 20px;")}
		margin-top: 40px;
		border-radius: 50px;
		width: 140px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--bs-gray-100);
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: var(--bs-secondary);
		border-color: var(--bs-gray-100);
	}
`;
