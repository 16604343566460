import { useState } from "react";
import styled from "styled-components";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

import emailjs from "emailjs-com";

import { useFormik } from "formik";
import * as yup from "yup";

export const YourTurnContainer = () => {
	const screen = useWindowMediaQuery();
	const [showAlert, setShowAlert] = useState(false);

	const validationSchema = yup.object().shape({
		firstName: yup.string().required("First name is required."),
		lastName: yup.string().required("Last name is required."),
		email: yup
			.string()
			.email("Enter valid email address.")
			.required("Email is required."),
		social_media: yup.string().required("Social Media Handles is required."),
		portfolio: yup.string().required("Portfolio is required."),
	});

	const formSubmit = (values) => {
		console.log("---form--values", values);
		setShowAlert(true);
		emailjs
			.send(
				process.env.REACT_APP_EMAIL_SERVICE_ID,
				process.env.REACT_APP_EMAIL_TEMPLATE_ID,
				values,
				process.env.REACT_APP_EMAIL_USER_ID,
			)
			.then(
				(result) => {
					setShowAlert(true);
					formik.resetForm();
					console.log(result.text, result);
				},
				(error) => {
					console.log(error.text, error);
				},
			);

		setTimeout(() => {
			setShowAlert(false);
		}, 5000);
	};

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			social_media: "",
			portfolio: "",
		},
		validationSchema: validationSchema,
		onSubmit: formSubmit,
	});

	return (
		<YourTurnContainerStyled screen={screen}>
			<HeadingContent screen={screen}>
				<h3>Now it's your turn</h3>
				<p>We love making new friends. Interested in joining our team?</p>
			</HeadingContent>

			<Row className="form-container flex-lg-row flex-md-column">
				<Col lg={4} md={8}>
					<p>
						Tell us about yourself, we’ll take it from there if we have an opening.
					</p>
				</Col>
				<Col lg={8} md={12}>
					<FormStyled screen={screen}>
						<Form onSubmit={formik.handleSubmit}>
							<Alert
								variant={"info"}
								show={showAlert}
								onClose={() => setShowAlert(false)}
								dismissable>
								We received your message, our team will reach out to you shortly!
							</Alert>
							<Row>
								<Form.Group as={Col} md={6} controlId="formGridFirstName">
									<FormLabelStyled
										error={formik.touched.firstName && Boolean(formik.errors.firstName)}>
										First Name
									</FormLabelStyled>
									{formik.touched.firstName && Boolean(formik.errors.firstName) && (
										<span class="material-icons">error</span>
									)}{" "}
									<Form.Control
										name="firstName"
										value={formik.values.firstName}
										onChange={formik.handleChange}
										isInvalid={
											formik.touched.firstName && Boolean(formik.errors.firstName)
										}
										type="text"
										placeholder="Please enter your first name"
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.firstName}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group as={Col} md={6} controlId="formGridLastName">
									<FormLabelStyled
										error={formik.touched.lastName && Boolean(formik.errors.lastName)}>
										Last Name
									</FormLabelStyled>
									{formik.touched.lastName && Boolean(formik.errors.lastName) && (
										<span class="material-icons">error</span>
									)}{" "}
									<Form.Control
										name="lastName"
										value={formik.values.lastName}
										onChange={formik.handleChange}
										isInvalid={formik.touched.lastName && Boolean(formik.errors.lastName)}
										type="text"
										placeholder="Please enter your last name"
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.lastName}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} md={6} controlId="formGridEmail">
									<FormLabelStyled
										error={formik.touched.email && Boolean(formik.errors.email)}>
										Email
									</FormLabelStyled>
									{formik.touched.email && Boolean(formik.errors.email) && (
										<span class="material-icons">error</span>
									)}{" "}
									<Form.Control
										name="email"
										value={formik.values.email}
										onChange={formik.handleChange}
										isInvalid={formik.touched.email && Boolean(formik.errors.email)}
										type="email"
										placeholder="Please enter your email"
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.email}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group as={Col} md={6} controlId="formGridPhone">
									<FormLabelStyled
										error={
											formik.touched.social_media && Boolean(formik.errors.social_media)
										}>
										Social Media Handles
									</FormLabelStyled>
									{formik.touched.social_media &&
										Boolean(formik.errors.social_media) && (
											<span class="material-icons">error</span>
										)}{" "}
									<Form.Control
										name="social_media"
										value={formik.values.social_media}
										onChange={formik.handleChange}
										isInvalid={
											formik.touched.social_media && Boolean(formik.errors.social_media)
										}
										type="text"
										placeholder="Linkedin, Instagram etc. "
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.social_media}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>

							<Row>
								<Form.Group as={Col} controlId="formGridPortfolio">
									<FormLabelStyled
										error={formik.touched.portfolio && Boolean(formik.errors.portfolio)}>
										Portfolio
									</FormLabelStyled>
									{formik.touched.portfolio && Boolean(formik.errors.portfolio) && (
										<span class="material-icons">error</span>
									)}{" "}
									<Form.Control
										name="portfolio"
										value={formik.values.portfolio}
										onChange={formik.handleChange}
										isInvalid={
											formik.touched.portfolio && Boolean(formik.errors.portfolio)
										}
										type="text"
										placeholder="Send us a link to your latest project and tell us about it "
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.portfolio}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<ButtonStyled type="submit" variant="dark">
								Submit
							</ButtonStyled>
						</Form>
					</FormStyled>
				</Col>
			</Row>
		</YourTurnContainerStyled>
	);
};

const YourTurnContainerStyled = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 60px auto;
	margin-bottom: 110px;
	max-width: var(--max-width-content);

	.form-container {
		p {
			max-width: 360px;
			font-weight: normal;
			font-size: 16px;
			line-height: 28px;
			color: var(--bs-primary);
			margin-bottom: 30px;
		}
	}
`;

const HeadingContent = styled.div`
	max-width: 360px;
	h3 {
		font-weight: ${({ screen }) => (screen.md ? "normal" : "bold")};
		font-size: ${({ screen }) => (screen.md ? "42px" : "24px")};
		line-height: ${({ screen }) => (screen.md ? "56px" : "42px")};
		color: #000;
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: var(--bs-primary);
		margin-bottom: 30px;
	}
`;

const FormLabelStyled = styled(Form.Label)`
	&.form-label {
		font-weight: normal;
		font-size: 14px;
		line-height: 21px;
		color: ${({ error }) => (error ? "#ee0004" : "var(--bs-gray-100)")};
	}
`;

const FormStyled = styled.div`
	h1 {
		font-weight: normal;
		font-size: 64px;
		line-height: 72px;
		max-width: 576px;
		color: var(--bs-primary);
		margin-bottom: 12px;
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		max-width: 531px;
		color: var(--bs-primary);
		margin-bottom: 51px;
	}

	.material-icons {
		position: absolute;
		color: #ee0004;
		margin-top: 10px;
		right: ${({ screen }) => (screen.lg ? "5%" : screen.md ? "-2.5%" : "-1%")};
	}

	.row > div {
		position: relative;
		margin-bottom: ${({ screen }) => (screen.md ? "40px" : "20px")};
	}

	.form-control,
	.form-select {
		width: ${({ screen }) => (screen.lg ? "90%" : "95%")};
		padding: 15px 12px;
		background-color: #fbfbfb;
		border: 1px solid #dde2e5;
		box-sizing: border-box;
		border-radius: 4px;
	}

	.form-control:focus,
	.form-select:focus {
		border-color: var(--bs-gray-100);
		outline: 0;
		box-shadow: none;
	}

	.text-area-message {
		width: 736px;
	}
	.form-select.is-invalid:not([multiple]):not([size]),
	.form-select.is-invalid:not([multiple])[size="1"],
	.was-validated .form-select:invalid:not([multiple]):not([size]),
	.was-validated .form-select:invalid:not([multiple])[size="1"] {
		background: none;
		border: 2px solid #ee0004;
	}

	.form-control.is-invalid,
	.was-validated .form-control:invalid {
		border: 2px solid #ee0004;
		background: none;
	}

	.invalid-feedback {
		color: #ee0004;
	}
`;

const ButtonStyled = styled(Button)`
	&.btn {
		margin-left: auto;
		margin-top: 40px;
		border-radius: 50px;
		width: 140px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--bs-gray-100);
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: var(--bs-secondary);
		border-color: var(--bs-gray-100);

		.material-icons {
			margin-left: 10px;
		}
	}
`;
