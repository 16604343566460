import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import {
	LandingPageStep1Animation,
	LandingPageStep2Animation,
	LandingPageStep3Animation,
	DesignDev,
	Deployment,
	Maintenance,
	DesignGifImg,
	DevelopmentGifImg,
	Design,
	Engineering,
} from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";
import { useState } from "react";

export const OnApproachSection = () => {
	const screen = useWindowMediaQuery();

	const [hoverState, setHoverState] = useState({
		design: false,
		development: false,
		deployment: false,
		maintenance: false,
	});

	const changeHoverState = (name, value) => {
		setHoverState({
			...hoverState,
			[name]: value,
		});
	};

	return (
		<OnApproachStyled screen={screen}>
			<h6>Our Approach</h6>
			<h3>How we do it</h3>

			{screen.md ? (
				<Box screen={screen}>
					<StepBox md={3} justify="flex-start" screen={screen}>
						<h6 className="step-heading">Step 01</h6>
						<h2 className="step-h2">Prototype Design</h2>

						<p>Interactive Wireframe design</p>
						<p>Based on an intensive competitive analysis</p>
					</StepBox>

					<AnimatedImage
						md={4}
						screen={screen}
						onMouseEnter={() => changeHoverState("design", true)}
						onMouseLeave={() => changeHoverState("design", false)}>
						<img
							src={hoverState.design ? LandingPageStep1Animation : DesignDev}
							alt=""
						/>
					</AnimatedImage>

					<StepBox md={3} justify="flex-end" screen={screen}>
						<h6 className="step-heading">Step 02</h6>
						<h2 className="step-h2">Development</h2>

						<p>Intensive yet agile development with extensive testing</p>
					</StepBox>
				</Box>
			) : (
				<>
					<StepImageAndText justify="flex-start" screen={screen}>
						<Col md={3} xs={5} sm={3} className="maintenance-img">
							<img
								src={hoverState.design ? Design : DesignGifImg}
								alt=""
								onMouseEnter={() => changeHoverState("design", true)}
								onMouseLeave={() => changeHoverState("design", false)}
							/>
						</Col>
						<Col md={9} xs={7} sm={9}>
							<StepThreeFourBox screen={screen}>
								<StepBox justify="flex-start" screen={screen}>
									<h6 className="step-heading">Step 01</h6>
									<h2 className="step-h2">Prototype Design</h2>

									<p>Interactive Wireframe design</p>
									<p>Based on an intensive competitive analysis</p>

								</StepBox>
							</StepThreeFourBox>
						</Col>
					</StepImageAndText>
					<StepImageAndText justify="flex-start" screen={screen}>
						<Col md={3} xs={5} sm={3} className="maintenance-img">
							<img
								onMouseEnter={() => changeHoverState("development", true)}
								onMouseLeave={() => changeHoverState("development", false)}
								src={hoverState.development ? Engineering : DevelopmentGifImg}
								alt=""
							/>
						</Col>
						<Col md={9} xs={7} sm={9}>
							<StepThreeFourBox screen={screen}>
								<StepBox justify="flex-start" screen={screen}>
									<h6 className="step-heading">Step 02</h6>
									<h2 className="step-h2">Development</h2>

									<p>Intensive yet agile development with extensive testing</p>
								</StepBox>
							</StepThreeFourBox>
						</Col>
					</StepImageAndText>
				</>
			)}

			<StepImageAndText justify="flex-start" screen={screen}>
				<Col md={3} xs={5} sm={3} className="maintenance-img">
					<img
						src={hoverState.deployment ? LandingPageStep2Animation : Deployment}
						alt=""
						onMouseEnter={() => changeHoverState("deployment", true)}
						onMouseLeave={() => changeHoverState("deployment", false)}
					/>
				</Col>
				<Col md={9} xs={7} sm={9}>
					<StepThreeFourBox screen={screen}>
						<StepBox justify="flex-start" screen={screen}>
							<h6 className="step-heading">Step 03</h6>
							<h2 className="step-h2">Deployment</h2>

							<p>Deployment of the first iteration with an additional extensive testing phase to ensure clientele needs are met</p>
						</StepBox>
					</StepThreeFourBox>
				</Col>
			</StepImageAndText>

			<StepImageAndText justify={"flex-start"} screen={screen}>
				{screen.md && <Col md={4}></Col>}
				<Col className="maintenance-img" md={3} xs={5} sm={3}>
					{" "}
					<img
						src={hoverState.maintenance ? LandingPageStep3Animation : Maintenance}
						alt=""
						onMouseEnter={() => changeHoverState("maintenance", true)}
						onMouseLeave={() => changeHoverState("maintenance", false)}
					/>
				</Col>
				<Col md={3} xs={7} sm={9}>
					<StepThreeFourBox screen={screen}>
						<StepBox justify="flex-start" screen={screen}>
							<h6 className="step-heading">Step 04</h6>
							<h2 className="step-h2">Maintenance</h2>

							<p>Security updates and upkeep of software to ensure consistent functionality + constant data backup</p>
						</StepBox>
					</StepThreeFourBox>
				</Col>
			</StepImageAndText>
		</OnApproachStyled>
	);
};

const OnApproachStyled = styled(Row)`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
	margin: ${({ screen }) => (screen.md ? "160px auto" : "20px auto")};

	h6 {
		font-size: 24px;
		line-height: 32px;
		color: var(--bs-primary);
	}

	h3 {
		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "42px" : "24px"};
		font-weight: bold;
		line-height: ${({ screen }) =>
			screen.xl ? "60px" : screen.md ? "56px" : "32px"};
		color: var(--bs-primary);
		margin-bottom: ${({ screen }) => (screen.md ? "70px" : "20px")};
	}
`;

const Box = styled(Row)`
	display: flex;
	max-width: ${({ screen }) => (screen.xl ? "80%" : "95%")};
	margin-left: ${({ screen }) => (screen.md ? "auto" : "0")};
	height: ${({ screen }) => (screen.md ? "296px" : "unset")};
	flex-direction: ${({ screen }) => (screen.md ? "row" : "column")};
	margin-bottom: 50px;
`;

const StepBox = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: ${({ justify }) => justify};

	.step-heading {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.md ? "24px" : "18px")};
		line-height: ${({ screen }) => (screen.md ? "32px" : "28px")};
		color: var(--bs-primary);
	}

	.step-h2 {
		font-weight: normal;
		font-size: ${({ screen }) =>
			screen.xl ? "42px" : screen.md ? "34px" : "24px"};
		line-height: ${({ screen }) =>
			screen.xl ? "56px" : screen.md ? "42px" : "32px"};
		color: var(--bs-primary);
	}

	p {
		font-size: 16px;
		line-height: 28px;
		color: var(--bs-primary);
	}
`;

const AnimatedImage = styled(Col)`
	padding: 0 20px;
	display: flex;
	align-items: center;

	img {
		margin-right: auto;
		width: -webkit-fill-available;
		max-height: 221px;
		max-width: 254px;
	}
`;

const StepImageAndText = styled(Row)`
	max-width: ${({ screen }) => (screen.xl ? "80%" : screen.md ? "95%" : "100%")};
	margin-left: ${({ screen }) => (screen.md ? "auto" : "0")};
	display: flex;
	flex-wrap: nowrap;
	justify-content: ${({ justify }) => justify};

	.maintenance-img {
		img {
			width: ${({ screen }) => (screen.md ? "159px" : "80px")};
			height: ${({ screen }) => (screen.md ? "159px" : "80px")};
			margin-right: ${({ screen }) => (screen.md ? "25px" : "10px")};
		}
	}
`;

const StepThreeFourBox = styled(Col)`
	display: flex;
	margin-bottom: ${({ screen }) => (screen.md ? "0" : "30px")};
	max-width: 287px;
`;
