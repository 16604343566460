import AboutPage from "./Pages/About";
import ApproachPage from "./Pages/Approach";
import CareerPage from "./Pages/Career";
import CaseStudyPage from "./Pages/CaseStudy";
import ContactPage from "./Pages/Contact";
import LandingPage from "./Pages/LandingPage";
import MarketingPage from "./Pages/Marketing";
import WaapPage from "./Pages/WaapPage";

export const routes = [
	{
		name: "Landing page",
		path: "/",
		component: LandingPage,
	},
	{
		name: "About page",
		path: "/about",
		component: AboutPage,
	},
	{
		name: "Approach page",
		path: "/approach",
		component: ApproachPage,
	},
	{
		name: "Marketing page",
		path: "/marketing",
		component: MarketingPage,
	},
	{
		name: "Contact page",
		path: "/contact",
		component: ContactPage,
	},
	{
		name: "Career page",
		path: "/career",
		component: CareerPage,
	},
	{
		name: "Case Study page",
		path: "/case-study/:organization", // organization names are 'pivot', 'float' and 'ottawa'
		component: CaseStudyPage,
	},
	{
		name: "WAAP Page",
		path: "/site",
		component: WaapPage,
	},
];
