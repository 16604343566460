import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { LandingPageCurves, LaptopCompGif } from "../../Utils/ImageImportFile";
import { WhoAreWeSection } from "./WhoAreWe";
import { CallWithTeam } from "./CallWithTeam";
import { DesignCatalog } from "./DesignCatalog";
import { PricingCards } from "./PricingCards";
import { OurValueProp} from "./OurValueProp"

import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const WaapPageComponent = () => {
	const screen = useWindowMediaQuery();

	return (
		<LandingPageStyled screen={screen}>
			<Row className="hero-section">
				<Col md={4}>
					{screen.md && (
						<>
							<h1 className="build-text">Build</h1>
							<h1 className="launch-text">Launch</h1>
							<h1 className="grow-text">Grow <span className="grow-sqaure">&#9726;</span></h1>
						</>
					)}
					{!screen.md && (
						<HeadingText>
							<h1>
								<span>Build</span>,
								Launch,
								<h1 className="grow-text">Grow <span className="grow-sqaure">&#9726;</span></h1>
							</h1>
						</HeadingText>
					)}
				</Col>
				<Col md={8}>
					<MainImageContainer screen={screen}>
						<img src={LaptopCompGif} alt="" />
					</MainImageContainer>
				</Col>
			</Row>

			{screen.md && (
				<>
					<CurvesBox>
						<img src={LandingPageCurves} alt="" />
					</CurvesBox>
				</>
			)}

			<OurValueProp />
			
			<DesignCatalog/>

			<PricingCards />

			<WhoAreWeSection />

			<CallWithTeam />
		</LandingPageStyled>
	);
};

const LandingPageStyled = styled.div`
	position: relative;

	.hero-section {
		max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
		margin: 0 auto;
		margin-top: ${({ screen }) => (screen.xl ? "100px" : "50px")};

		h1 {
			color: var(--bs-primary);
			font-size: ${({ screen }) => (screen.xl ? "96px" : "60px")};
			line-height: 117%;
		}

		.build-text {
			font-weight: 500;
		}

		.launch-text {
			margin-left: 30px;
			font-weight: 300;
		}

		.grow-text {
			font-weight: 900;
			background: ${(
				{ screen }) => (!screen.md ?
							"-webkit-linear-gradient(0deg, var(--bs-gradient-yellow), var(--bs-gradient-green) 90%);"
							: "-webkit-linear-gradient(0deg, var(--bs-gradient-yellow), var(--bs-gradient-green) 55%);"
						)};
			-webkit-background-clip: text;
  			-webkit-text-fill-color: transparent;
		}

		.grow-sqaure{
			font-size: 0.2em;
			margin-left: -10px;
		}
	}
`;

const MainImageContainer = styled.div`
	text-align: ${({ screen }) => (screen.xl ? "right" : "center")};

	img {
		width: ${({ screen }) => (screen.xl ? "80%" : screen.md ? "90%" : "100%")};
	}
`;

const CurvesBox = styled.div`
	position: absolute;
	top: -10%;
	left: 0;
	width: 100%;
	z-index: -1;

	img {
		width: inherit;
	}
`;

const HeadingText = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	h1 {
		text-align: center;
		font-weight: 300;
		font-size: 42px;
		line-height: 56px;
	}
	span {
		font-weight: normal;
	}

	b {
		font-weight: bold;
	}
`;
