import { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import {
	Design,
	Engineering,
	Growth,
	Research,
	Strategy,
	Evaluation,
	ResearchJpeg,
	StrategyJpeg,
	ProductDesignJpeg,
	EngineeringJpeg,
	LaunchGrowJpeg,
	EvaluationJpeg,
} from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

const fieldsContent = [
	{
		gif: Research,
		img: ResearchJpeg,
		name: "Research",
		text:
			"We believe in a thorough, yet efficient, research phase in order to make sure we and our client partners have all we need to make informed product decisions.",
	},
	{
		gif: Strategy,
		img: StrategyJpeg,
		name: "Strategy",
		text:
			"We craft research-backed product strategies that help our process move through the key execution stages in an efficient manner, without the need to backtrack.",
	},
	{
		gif: Design,
		img: ProductDesignJpeg,
		name: "Product Design",
		text:
			"Our in-house creative and design team is passionate about aesthetics and user experience, following the latest trends but also crafting new styles to disrupt the market.",
	},
	{
		gif: Engineering,
		img: EngineeringJpeg,
		name: "Engineering",
		text:
			"Our in-house engineering group is made up of industry veterans who have “been there, done that,” and we leverage that deep experience to protect and support our client partners.",
	},
	{
		gif: Growth,
		img: LaunchGrowJpeg,
		name: "Launch & Growth",
		text:
			"Building a product is only the first step. Ongoing management and maintenance of the digital products we create are where the real challenge, and fun, begins.",
	},
	{
		gif: Evaluation,
		img: EvaluationJpeg,
		name: "Evaluation & Improvement",
		text:
			"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something more. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.",
	},
];

export const FieldsContentSection = () => {
	const screen = useWindowMediaQuery();
	const [selectedHover, setSelectedHover] = useState(null);
	return (
		<FieldIntroStyled screen={screen}>
			{fieldsContent.map((singleCard, index) => (
				<Col key={index} md={4} className="card-styled">
					<Row className="card-header-styled flex-nowrap">
						<Col
							md={4}
							xs={3}
							className="p-0"
							onMouseEnter={() => setSelectedHover(singleCard.name)}
							onMouseLeave={() => setSelectedHover(null)}>
							<img
								src={
									selectedHover === singleCard.name ? singleCard.gif : singleCard.img
								}
								width={screen.xl ? "130px" : screen.md ? "96px" : "112px"}
								height={screen.xl ? "125px" : screen.md ? "95px" : "127px"}
								alt=""
							/>
						</Col>
						<Col xs={9} className="d-flex align-items-center">
							<h4> {singleCard.name}</h4>
						</Col>
					</Row>

					<p>{singleCard.text}</p>
				</Col>
			))}
		</FieldIntroStyled>
	);
};

const FieldIntroStyled = styled(Row)`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 0 auto;
	max-width: var(--max-width-content);

	.card-styled {
		margin-bottom: 100px;
		.card-header-styled {
			margin-bottom: ${({ screen }) => (screen.md ? "25px" : "0")};
			margin-left: 0;
			margin-right: 0;
		}
	}

	h4 {
		font-weight: bold;
		font-size: ${({ screen }) => (screen.xl ? "24px" : "20px")};
		line-height: 42px;
		color: var(--bs-primary);
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: var(--bs-primary);
	}

	img {
		margin-left: -20px;
	}
`;
