import styled from "styled-components";
import { Row, Col, Button } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const QueriesContainer = () => {

	const triggerEmailAction = (emailAddress) => {
		window.location = `mailto:${emailAddress}`;
	};

	const screen = useWindowMediaQuery();
	return (
		<QueryContainerStyled screen={screen}>
			<Row>
				<Col>
					<h3>For new project inquiries</h3>
					<h6>Team@exploratorlabs.com</h6>

					<ButtonStyled variant="outline-dark" onClick={() => triggerEmailAction("team@exploratorlabs.com")}>Send</ButtonStyled>
				</Col>
				<Col>
					<h3>For media inquiries</h3>
					<h6>Media@exploratorlabs.com</h6>
					<ButtonStyled variant="outline-dark" onClick={() => triggerEmailAction("media@exploratorlabs.com")}>Send</ButtonStyled>
				</Col>
				<Col>
					<h3>For career inquiries</h3>
					<h6>Join@exploratorlabs.com</h6>
					<ButtonStyled variant="outline-dark" onClick={() => triggerEmailAction("join@exploratorlabs.com")}>Send</ButtonStyled>
				</Col>
			</Row>
		</QueryContainerStyled>
	);
};

const QueryContainerStyled = styled.div`
	max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : screen.md ? "90%" : "100%")};
	margin: 72px auto;
	margin-bottom: 94px;

	h3 {
		margin-top: ${({ screen }) => (screen.md ? "0" : "30px")};
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		margin-bottom: ${({ screen }) => (screen.md ? "24px" : "2px")};
	}

	h6 {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		margin-bottom: ${({ screen }) => (screen.md ? "24px" : "5px")};
		color: #000000;
	}
`;

const ButtonStyled = styled(Button)`
	&.btn {
		margin-top: 22px;
		border-radius: 50px;
		width: 128px;
		height: 40px;
		padding: 8px auto;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		border-color: var(--bs-gray-100);
	}
`;
