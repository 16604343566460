import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const TeamCards = ({ teamContent }) => {
	const screen = useWindowMediaQuery();
	return (
		<TeamCardContainer screen={screen}>
			{teamContent.map((team, index) => (
				<Col md={4} key={index}>
					<TeamCardStyled screen={screen}>
						<h4>{team.title}</h4>
						<p>{team.text}</p>
					</TeamCardStyled>
				</Col>
			))}
		</TeamCardContainer>
	);
};

const TeamCardContainer = styled(Row)`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: 0 auto;
	max-width: var(--max-width-content);

	> div {
		padding: ${({ screen }) => (screen.md ? "" : "0")};
		margin-bottom: ${({ screen }) => (screen.md ? "0" : "32px")};
	}
`;
const TeamCardStyled = styled.div`
	h4 {
		font-weight: ${({ screen }) => (screen.md ? "normal" : "bold")};
		height: ${({ screen }) =>
			screen.lg ? "84px" : screen.md ? "126px" : "unset"};
		font-size: ${({ screen }) =>
			screen.xl ? "24px" : screen.md ? "34px" : "24px"};
		line-height: ${({ screen }) => (screen.xl ? "32px" : "42px")};
		margin-bottom: ${({ screen }) => (screen.md ? "10px" : "4px")};
		color: var(--bs-primary);
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 28px;
		color: var(--bs-primary);
	}
`;
