import { FooterComp } from "../Components/Shared/Footer/Footer";
import { HeaderComp } from "../Components/Shared/Header/Header";

const CustomLayout = ({ children }) => {
	return (
		<>
			<HeaderComp />
			{children}
			<FooterComp />
		</>
	);
};

export default CustomLayout;
