import { LandingPageComponent } from "../Components/Root/LandingPage";
import CustomLayout from "../Layout/Layout";

const LandingPage = () => {
	return (
		<CustomLayout>
			<LandingPageComponent />
		</CustomLayout>
	);
};

export default LandingPage;
