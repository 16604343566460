import { useState, useEffect } from "react";

const useWindowMediaQuery = () => {
	const [windowWidth, setWindowWidth] = useState(getWindowDimensions());

	function getWindowDimensions() {
		const width = window ? window.innerWidth : null;
		let obj = {
			sm: false,
			xs: false,
			md: false,
			lg: false,
			xl: false,
			xxl: false,
		};

		if (width) {
			if (width <= 576) {
				obj = {
					sm: false,
					xs: true,
					md: false,
					lg: false,
					xl: false,
					xxl: false,
				};
			} else if (width <= 768) {
				obj = {
					xs: true,
					sm: false,
					md: false,
					lg: false,
					xl: false,
					xxl: false,
				};
			} else if (width <= 992) {
				obj = {
					sx: false,
					sm: true,
					md: true,
					lg: false,
					xl: false,
					xxl: false,
				};
			} else if (width <= 1200) {
				obj = {
					xs: false,
					sm: true,
					md: true,
					lg: true,
					xl: false,
					xxl: false,
				};
			} else if (width <= 1400) {
				obj = {
					xs: false,
					sm: true,
					md: true,
					lg: true,
					xl: true,
					xxl: false,
				};
			} else {
				obj = {
					xs: false,
					sm: true,
					md: true,
					lg: true,
					xl: true,
					xxl: true,
				};
			}
		}
		return obj;
	}

	useEffect(() => {
		setWindowWidth(getWindowDimensions());

		window.addEventListener("resize", () => {
			setWindowWidth(getWindowDimensions());
		});
		return window.removeEventListener("resize", () => {});
	}, []);

	return windowWidth;
};

export default useWindowMediaQuery;
