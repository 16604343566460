import { Row, Col, Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";
import { FooterLogo } from "../../Utils/ImageImportFile";

export function FooterComp() {
	const history = useHistory();
	const screen = useWindowMediaQuery();
	return (
		<Footerstyled screen={screen}>
			<Container className="footer-wrapper" fluid>
				<LogoStyled screen={screen}>
					<img src={FooterLogo} alt="" />
				</LogoStyled>

				<FooterContent screen={screen}>
					<Col lg={4} md={5}>
						<h6>Have An Idea?</h6>
						<Box screen={screen}>
							<h2>Tell Us about it</h2>
							<Button variant="outline-light" onClick={() => history.push("/contact")}>
								Contact Us
							</Button>
							{screen.sm && !screen.lg && (
							<ContactUsBox screen={screen}>
								<h6>Say Hello!</h6>
								<p>111 Peter Street, Suite 700</p>
								<p>Toronto, Canada</p>
								<p>M5V2H1</p>
							</ContactUsBox>
							)}
						</Box>
					</Col>
					<Col lg={8} md={7}>
						<FooterLinksContainer screen={screen}>
							<div>
								<FooterLinksHeading>Services</FooterLinksHeading>
								<ul>
									<li>
										<a href="/">IOS App Development</a>
									</li>
									<li>
										<a href="/">Android App Development</a>
									</li>
									<li>
										<a href="/">Web Platform Development</a>
									</li>
									<li>
										<a href="/">UX/UI Strategy & Design</a>
									</li>
								</ul>
							</div>

							<div>
								<FooterLinksHeading>About</FooterLinksHeading>
								<ul>
									<li>
										<a href="/approach">Our Approach</a>
									</li>
									<li>
										<a href="/career">We’re Hiring</a>
									</li>
									<li>
										<a href="/contact">Contact Us</a>
									</li>
									<li>
										<a href="/contact">Questions?</a>
									</li>
								</ul>
							</div>

							{screen.lg && (
								<div>
									<FooterLinksHeading>Say Hello!</FooterLinksHeading>
									<ul>
										<li>
											<a href="/approach">111 Peter Street, Suite 700</a>
										</li>
										<li>
											<a href="/career">Toronto, Ontario</a>
										</li>
										<li>
											<a href="/contact">Canada</a>
										</li>
										<li>
											<a href="/contact">M5V2H1</a>
										</li>
									</ul>
								</div>
							)}

						</FooterLinksContainer>
					</Col>
				</FooterContent>

				{!screen.sm && (
					<ContactUsBox screen={screen}>
						<h6>Say Hello!</h6>
						<p>111 Peter Street, Suite 700</p>
						<p>Toronto, Canada</p>
						<p>M5V2H1</p>
					</ContactUsBox>
				)}
			</Container>
		</Footerstyled>
	);
}

const Footerstyled = styled.div`
	background: var(--bs-primary);
	padding-top: 50px;
	padding-bottom: 110px;

	.footer-wrapper {
		max-width: var(--max-width-content);
	}
`;

const LogoStyled = styled.div`
	max-width: ${({ screen }) => (screen.xxl ? "unset" : screen.sm ? "90%" : "98%")};
	margin-bottom: 50px;
	margin: 0 auto;

	img {
		width: 300px;
		margin-left: 15px;
		margin-bottom: 50px;
	}
`;

const FooterContent = styled(Row)`
	color: var(--bs-secondary);
	max-width: ${({ screen }) => (screen.xxl ? "unset" : screen.sm ? "90%" : "98%")};
	margin: 0 auto;
	h6 {
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
	}
`;

const Box = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ screen }) => (screen.lg ? "30px" : "unset")};
	flex-wrap: ${({ screen }) => (screen.xl ? "none" : "wrap")};

	h2 {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.xl ? "42px" : "32px")};
		line-height: ${({ screen }) => (screen.xl ? "56px" : "44px")};
		margin-right: 20px;
	}

	.btn {
		margin-top: ${({ screen }) => (screen.xl ? "0" : "30px")};
		padding: 8px 26px;
		border-radius: 50px;
		width: 150px;
	}
`;

const ContactUsBox = styled.div`
	max-width: 200px;
	margin-top: ${({ screen }) => (screen.sm ? "30px" : "50px")};
	margin-left: ${({ screen }) => (screen.sm ? "unset" : "auto")};
	margin-right: ${({ screen }) => (screen.sm ? "unset" : "auto")};
	text-align: ${({ screen }) => (screen.sm ? "left" : "center")};

	${({ screen }) => (screen.sm ? "" : "padding-left : 12px")};

	h6 {
		font-size: 24px;
		color: var(--bs-secondary);
	}
	p {
		font-weight: normal;
		font-size: 14px;
		line-height: ${({ screen }) => (screen.sm ? "24px" : "34px")};
		color: var(--bs-secondary);
	}
`;

const FooterLinksContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: ${({ screen }) => (screen.sm ? "space-around" : "space-between")};

	div {
		margin-top: ${({ screen }) => (screen.sm ? "0" : "63px")};
		margin-bottom: ${({ screen }) => (screen.sm ? "0" : "50px")};
	}

	& > div:nth-of-type(1), div:nth-of-type(2) {
		margin-right: ${({ screen }) => (screen.xl ? "70px" : "30px")};
	}

	ul {
		padding: 0;
		list-style-type: none;

		li {
			cursor: pointer;

			font-size: ${({ screen }) => (screen.xl ? "18px" : "16px")};
			line-height: 30px;
			margin-bottom: 20px;

			a {
				color: var(--bs-secondary);
				text-decoration: none;
			}
		}
	}

	& > div:nth-of-type(3){
		li {
			margin-bottom: unset;
		}
	}
`;

const FooterLinksHeading = styled.h4`
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 31px;
	color: var(--bs-secondary);
	font-weight: 300;
`;
