import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import {
	Career_Purpose_1,
	Career_Purpose_2,
	Career_Purpose_3,
} from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const HavingFunSection = () => {
	const screen = useWindowMediaQuery();
	return (
		<HavingFunSectionStyled screen={screen}>
			<Row>
				<Col md={4} className="d-flex justify-content-center flex-column pe-5">
					<h4>
						Is it really work if you're having <b>fun</b>?
					</h4>
					<p>
						The kind of work we do matters, and we value the time our folks put into
						our success.
					</p>
				</Col>
				<Col md={8} className="d-flex justify-content-end">
					<div className="d-flex images-container">
						<div
							md={6}
							className="single-image-col d-flex align-items-center justify-content-center">
							<img src={Career_Purpose_3} alt="" />
						</div>
						<div
							md={6}
							className="d-flex align-items-start flex-column two-images-col">
							<div className="two-images-col-image1">
								<img src={Career_Purpose_1} alt="" />
							</div>
							<div className="two-images-col-image2">
								<img src={Career_Purpose_2} alt="" />
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</HavingFunSectionStyled>
	);
};

const HavingFunSectionStyled = styled.div`
	width: ${({ screen }) => (screen.xl ? "80%" : "90%")};
	margin: ${({ screen }) => (screen.md ? "80px auto" : "50px auto")};
	margin-bottom: ${({ screen }) => (screen.md ? "130px" : "40px")};
	max-width: var(--max-width-content);

	h4 {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.md ? "32px" : "24px")};
		line-height: ${({ screen }) => (screen.md ? "48px" : "32px")};
		color: #000;
	}

	p {
		font-size: 18px;
		line-height: 30px;
		color: #000;
		margin-top: 30px;
	}

	.images-container {
		width: ${({ screen }) => (screen.xxl ? "80%" : "100%")};
		justify-content: space-between;
		flex-direction: ${({ screen }) => (screen.md ? "row" : "column")};

		.two-images-col {
			width: ${({ screen }) => (screen.md ? "48%" : "100%")};
		}
	}

	.single-image-col {
		width: ${({ screen }) => (screen.md ? "48%" : "100%")};
		img {
			margin: ${({ screen }) => (screen.md ? "0" : "30px 0")};
			width: -webkit-fill-available;
		}
	}

	.two-images-col-image2,
	.two-images-col-image1 {
		width: -webkit-fill-available;
	}
	.two-images-col-image1 > img {
		width: -webkit-fill-available;
		margin-bottom: 18px;
	}

	.two-images-col-image2 > img {
		width: -webkit-fill-available;
		margin: ${({ screen }) => (screen.md ? "0" : "30px 0")};
	}
`;
