import styled from "styled-components";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";
import { ContactPageCurves } from "../../Utils/ImageImportFile";
import { ContactForm } from "./Form";
import { QueriesContainer } from "./Queries";

export const ContactPageComponent = () => {
	const screen = useWindowMediaQuery();
	return (
		<ContactPageStyled screen={screen}>
			{screen.lg && (
				<CurvesStyled>
					<img src={ContactPageCurves} alt="" />
				</CurvesStyled>
			)}

			<ContactForm />
			<QueriesContainer />
		</ContactPageStyled>
	);
};

const ContactPageStyled = styled.div`
	width: ${({ screen }) => (screen.md ? "unset" : "90%")};
	margin: 0 auto;
`;

const CurvesStyled = styled.div`
	margin-top: 20px;
	position: relative;

	img {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
`;
