import styled from "styled-components";
import { Navbar} from "react-bootstrap";
import { HeaderLogo, HeaderLogoMobile } from "../../Utils/ImageImportFile";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export function WaapHeaderComp() {
	const screen = useWindowMediaQuery();
	return (
		<HeaderStyled screen={screen}>
			<Navbar>
				<Navbar.Brand href="/">
					<img src={HeaderLogo} alt="" />
				</Navbar.Brand>
			</Navbar>
		</HeaderStyled>
	);
}

const HeaderStyled = styled.div`
	.navbar-brand > img {
		width: ${({ screen }) => (screen.md ? "240px" : "200px")};
		height: ${({ screen }) => (screen.md ? "40px" : "35px")};
	}
	.navbar {
		max-width: ${({ screen }) => (screen.xxl ? "var(--max-width-content)" : "90%")};
		margin: 0 auto;
		padding: 40px 0;

		.nav-link {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 30px;
			color: var(--bs-primary);
			padding: 0;
			margin: 0 22px;
		}

		.navbar-nav .nav-link.active,
		.navbar-nav .show > .nav-link {
			border-bottom: 2px solid var(--bs-primary);
		}
	}
`;