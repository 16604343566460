import { WaapPageComponent } from "../Components/Root/WaapPage";
import WaapCustomLayout from "../Layout/WaapLayout";

const WaapPage = () => {
	return (
		<WaapCustomLayout>
			<WaapPageComponent />
		</WaapCustomLayout>
	);
};

export default WaapPage;
