import styled from "styled-components";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const ProjectScobeContainer = ({ text }) => {
	const screen = useWindowMediaQuery();
	return (
		<ProjectScobeStyled screen={screen}>
			<h4>Project Overview</h4>
			<h2>Project Scope</h2>
			<p>{text}</p>
		</ProjectScobeStyled>
	);
};

const ProjectScobeStyled = styled.div`
	h4 {
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		color: var(--bs-primary);
	}

	h2 {
		font-weight: ${({ screen }) =>
			screen.xl ? "bold" : screen.md ? "normal" : "bold"};
		font-size: ${({ screen }) =>
			screen.xl ? "50px" : screen.md ? "40px" : "24px"};
		line-height: ${({ screen }) =>
			screen.xl ? "64px" : screen.md ? "52px" : "42px"};
		color: var(--bs-primary);
		margin-bottom: ${({ screen }) => (screen.md ? "37px" : "10px")};
	}
	p {
		max-width: 550px;
		font-weight: normal;
		font-size: ${({ screen }) => (screen.xl ? "18px" : "16px")};
		line-height: ${({ screen }) => (screen.xl ? "30px" : "28px")};
		color: var(--bs-primary);
	}
`;
