import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import useWindowMediaQuery from "../../../Hooks/MediaQuery";

export const BusinessOutcomeContainer = ({ data, image }) => {
	const screen = useWindowMediaQuery();
	return (
		<BusinessOutcomeStyled screen={screen}>
			<Row>
				<Col lg={5} md={6} className="d-flex flex-column justify-content-center">
					<h4>Services</h4>
					<h2>Business Outcome</h2>
					{data.map((point, index) => (
						<p>{point}</p>
					))}
				</Col>
				<Col lg={7} md={6} className="text-right d-flex align-items-end">
					<img className="img-fluid" src={image} alt="" />
				</Col>
			</Row>
		</BusinessOutcomeStyled>
	);
};

const BusinessOutcomeStyled = styled.div`
	margin-bottom: 130px;

	h4 {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.xl ? "24px" : "20px")};
		line-height: ${({ screen }) => (screen.xl ? "32px" : "26px")};
		color: var(--bs-primary);
	}

	h2 {
		margin-bottom: ${({ screen }) => (screen.md ? "35px" : "14px")};
		font-weight: bold;
		font-size: ${({ screen }) => (screen.xl ? "50px" : "24px")};
		line-height: ${({ screen }) => (screen.xl ? "64px" : "42px")};
		color: var(--bs-primary);
	}

	p {
		font-weight: normal;
		font-size: ${({ screen }) => (screen.xl ? "18px" : "16px")};
		line-height: ${({ screen }) => (screen.xl ? "30px" : "28px")};
		color: var(--bs-primary);
	}

	img {
		width: 100%;
		max-width: 500px;
		margin-top: ${({ screen }) => (screen.md ? "0" : "30px")};
		margin-left: auto;
	}
`;
